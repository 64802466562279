const configurationsList = [
  {
    id: "0",
    title: "Autonomous Forklift",
    category: "",
    subList: [
      {
        id: "0",
        title: "Automonous Forklift#1",
        category: "",
        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "40",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "Automonous Forklift#2",
        category: "",
        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "125",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "30",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "50",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "5.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        title: "Automonous Forklift#3",
        category: "",
        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "225",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "30",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "50",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "7.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "18",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "3",
        title: "Automonous Forklift#4",
        category: "",
        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "40",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "25",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "40",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "21",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "5.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "4",
        title: "Automonous Forklift#5",
        category: "",
        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "60",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "120",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "30",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "25",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "1",
    title: "AI Camera",
    category: "",
    subList: [
      {
        id: "0",
        title: "AI Camera#23",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "40",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "AI Camera#24",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "60",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "135",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "45",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "28",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "2",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "Robotic Arm Packager",
    category: "",
    subList: [
      {
        id: "0",
        title: "Robotic Arm Packager #13",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "120",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "40",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "22",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "Robotic Arm Packager #14",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "60",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "125",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "35",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "50",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "20",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "21",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        title: "Robotic Arm Packager #15",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "60",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "125",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "30",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "40",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "25",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "1",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "3",
    title: "Conveyor Belt",
    category: "",
    subList: [
      {
        id: "0",
        title: "Conveyor Belt#02",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Tension (N/mm)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: ">",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Belt Speed (m/s)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4",
              symbol: "<",
              range: "4",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "12",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "Conveyor Belt#03",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "-40",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "257",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Tension (N/mm)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "24",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "2",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Belt Speed (m/s)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "4",
              symbol: "<",
              range: "4",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "12",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "4",
    title: "Pallet",
    category: "",
    subList: [
      {
        id: "0",
        title: "Pallet #2",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "22",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "3.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "27",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "5",
    title: "Packager",
    category: "",
    subList: [
      {
        id: "0",
        title: "Packager #1",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "20",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "6",
    title: "Autonomous Mobile Robot",
    category: "",
    subList: [
      {
        id: "0",
        title: "AMR#23",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "30",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "18",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "18",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "1",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "AMR#24",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "23",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "41",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "17",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "6.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "25",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "1",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        title: "AMR#25",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "50",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "15",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "3.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "2",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "3",
        title: "AMR#26",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Humidity (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "40",
            },
            max: {
              label: "Max",
              value: "50",
              symbol: ">",
              range: "60",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Charge (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "20",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "alert",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "19",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "5.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "9",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "20",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "7",
    title: "Equipment",
    category: "",
    subList: [
      {
        id: "0",
        title: "Equipment#1",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "21",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "6",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "30",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "3",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "1",
        title: "Equipment#2",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "2",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "20",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "7.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "8",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "22",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "5",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
      {
        id: "2",
        title: "Equipment#3",
        category: "",

        cardList: [
          {
            title: "Temperature (°C)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "10",
              symbol: "<",
              range: "14",
            },
            max: {
              label: "Max",
              value: "25",
              symbol: ">",
              range: "122",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Opr.Hrs (Hrs)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "1",
              symbol: "<",
              range: "2",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "operation",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Power Usage (KW)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "19",
              symbol: ">",
              range: "25",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Vibration (mm)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "10",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Pressure (hPa)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "5.5",
              symbol: "<",
              range: "7.5",
            },
            max: {
              label: "Max",
              value: "7",
              symbol: ">",
              range: "10",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Equipment Efficiency (%)",
            isEnabled: true,
            min: {
              label: "Min",
              value: "29",
              symbol: "<",
              range: "30",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
          {
            title: "Maintenance Hours (Hrs)",
            isEnabled: true,
            max: {
              label: "Max",
              value: "4",
              symbol: ">",
              range: "5",
            },
            smsChecked: true,
            emailChecked: true,
            configurationType: "event",
            addedContacts: [],
            availableContacts: [
              {
                id: "0",
                name: "Mike Ross",
                img: "",
                isChecked: false,
              },
              {
                id: "1",
                name: "Jessica",
                img: "",
                isChecked: false,
              },
              {
                id: "2",
                name: "James William",
                img: "",
                isChecked: false,
              },
              {
                id: "3",
                name: "John William",
                img: "",
                isChecked: false,
              },
              {
                id: "4",
                name: "William Alexander",
                img: "",
                isChecked: false,
              },
              {
                id: "5",
                name: "Michael John",
                img: "",
                isChecked: false,
              },
              {
                id: "6",
                name: "Christopher",
                img: "",
                isChecked: false,
              },
              {
                id: "7",
                name: "Jack",
                img: "",
                isChecked: false,
              },
              {
                id: "8",
                name: "Johnny Depp",
                img: "",
                isChecked: false,
              },
              {
                id: "9",
                name: "Michael",
                img: "",
                isChecked: false,
              },
              {
                id: "10",
                name: "Jacob",
                img: "",
                isChecked: false,
              },
              {
                id: "11",
                name: "Ethan",
                img: "",
                isChecked: false,
              },
              {
                id: "12",
                name: "Henry William",
                img: "",
                isChecked: false,
              },
              {
                id: "13",
                name: "Samuel",
                img: "",
                isChecked: false,
              },
              {
                id: "14",
                name: "William Benjamin",
                img: "",
                isChecked: false,
              },
              {
                id: "15",
                name: "Nolan",
                img: "",
                isChecked: false,
              },
              {
                id: "16",
                name: "Logan",
                img: "",
                isChecked: false,
              },
              {
                id: "17",
                name: "Mason",
                img: "",
                isChecked: false,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default configurationsList;
