import { useState, useEffect } from "react";
import theme from "theme/theme";
import EquipmentsListItems from "components/EquipmentsListItems";
import SearchBox from "elements/SearchBox";
import CloseIcon from "../../assets/closeIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import useStyles from "./styles";

const EquipmentsList = (props: any) => {
  const { equipmentsList } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    rootContainer,
    headingContainer,
    title,
    searchImage,
    listSection,
    searchClass,
  } = useStyles(appTheme);

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>([]);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    setSearchData(equipmentsList);
  }, [equipmentsList]);

  const handleSelectedIndex = (index: any) => {
    props.handleSelectedIndex(index);
  };

  const handleSelectedListItemIndex = (index: any) => {
    props.handleSelectedListItemIndex(index);
  };

  const handleSearch = (searchValue: any) => {
    let searchResult = equipmentsList?.filter((value: any) => {
      return (
        value?.title
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.area
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase())
      );
    });
    setSearchData(searchResult);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchData(equipmentsList);
  };

  return (
    <div className={rootContainer}>
      <div className={headingContainer}>
        {!searchOpen ? (
          <div className={title}>Equipment</div>
        ) : (
          <SearchBox
            searchInput={searchClass}
            placeHolder={"Search"}
            handleSearch={handleSearch}
            searchIsOpen={true}
            fontColor={appTheme?.palette?.dashboardList?.darkGrey3}
          />
        )}
        <div className={searchImage}>
          <img
            src={searchOpen ? CloseIcon : SearchIcon}
            alt="search"
            onClick={searchOpen ? handleSearchClose : handleSearch}
          />
        </div>
      </div>
      <div className={listSection}>
        <EquipmentsListItems
          equipmentsList={searchData}
          handleSelectedIndex={handleSelectedIndex}
          handleSelectedListItemIndex={handleSelectedListItemIndex}
        />
      </div>
    </div>
  );
};

export default EquipmentsList;
