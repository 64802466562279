import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import LocationIcon from "../../assets/locationIcon.svg";
import TemperatureIcon from "../../assets/listItemIcons/temperature.svg";
import TemperatureIcon2 from "../../assets/listItemIcons/temperature2.svg";
import BeltSpeedIcon from "../../assets/listItemIcons/beltSpeed.svg";
import ChargeIcon from "../../assets/listItemIcons/charge.svg";
import DaysMaintanenceIcon from "../../assets/listItemIcons/daysMaintanence.svg";
import HumidityIcon from "../../assets/listItemIcons/humidity.svg";
import OperationalHoursIcon from "../../assets/listItemIcons/operationalHours.svg";
import OprHrsIcon from "../../assets/listItemIcons/oprHrs.svg";
import PackagesCompletedIcon from "../../assets/listItemIcons/packagesCompleted.svg";
import PalletsCompletedIcon from "../../assets/listItemIcons/palletsCompleted.svg";
import PressureIcon from "../../assets/listItemIcons/pressure.svg";
import ProductivityScoreIcon from "../../assets/listItemIcons/productivityScore.svg";
import RejectsIcon from "../../assets/listItemIcons/rejects.svg";
import SafetyInfractionsIcon from "../../assets/listItemIcons/safetyInfractions.svg";
import SafetyScoreIcon from "../../assets/listItemIcons/safetyScore.svg";
import TensionIcon from "../../assets/listItemIcons/tension.svg";
import TripsRemainingIcon from "../../assets/listItemIcons/tripsRemaining.svg";
import VibrationIcon from "../../assets/listItemIcons/vibration.svg";
import Button from "../../elements/Button";
import useTranslation from "../../localization/translations";
import useStyles from "./styles";

const DashboardListItems: React.FC<any> = (props) => {
  const {
    data,
    data: {
      title,
      area,
      id,
      category,
      temparature,
      humidity,
      oprHours,
      powerUsage,
      currentTimeStamp,
    },
    handleExpandListItem,
    index,
    selectedNotification,
    handleViewDialogue,
    refs,
    notificationTimeStamp,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [searchOpen, setSearchOpen] = useState<any>(false);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    listItemContainer,
    listItemTitle,
    listItemLocation,
    expandedListItem,
    collapsedListItem,
    locationIconStyle,
    listItemIconDetails,
    listIemIcon,
    expandedListIconContainer,
    selectedButtonStyles,
    listItemFooterStyle,
    listItemIconName,
    listItemTimeStyle,
    listItemSection,
  } = useStyles(appTheme);

  const {
    itemIconList1,
    itemIconList2,
    itemIconList3,
    itemIconList4,
    itemListButton,
  } = useTranslation();

  const [listItemIconArray, setListItemIconArray] = useState<any>();

  useEffect(() => {
    let iconListArray: any = [];
    if (data) {
      switch (data.category) {
        case "forklift":
          iconListArray = [
            {
              icon: TemperatureIcon,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: TripsRemainingIcon,
              value: `${data?.tripsRemaining}`,
              label: "Trips Remaining",
            },
            {
              icon: OprHrsIcon,
              value: `${data?.oprHours}Hrs`,
              label: "Opr. Hrs",
            },
            {
              icon: ChargeIcon,
              value: `${data?.charge}%`,
              label: "Charge",
            },
          ];
          break;
        case "aiCamera":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: HumidityIcon,
              value: `${data?.humidity}%`,
              label: "Humidity",
            },
            {
              icon: RejectsIcon,
              value: `${data?.rejects}`,
              label: "Rejects",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;
        case "robotArm":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: HumidityIcon,
              value: `${data?.humidity}%`,
              label: "Humidity",
            },
            {
              icon: DaysMaintanenceIcon,
              value: `${data?.daysMaintanence}`,
              label: "Days Until Maintenance",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;
        case "conveyerBelt":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: BeltSpeedIcon,
              value: `${data?.beltSpeed}rpm`,
              label: "Belt Speed",
            },
            {
              icon: TensionIcon,
              value: `${data?.tension}N/mm`,
              label: "Tension",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;
        case "pallet":
          iconListArray = [
            {
              icon: TemperatureIcon,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: PalletsCompletedIcon,
              value: `${data?.palletsCompleted}%`,
              label: "Pallets Completed",
            },
            {
              icon: DaysMaintanenceIcon,
              value: `${data?.daysMaintanence}`,
              label: "Days Untill Maintanence",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;
        case "packager":
          iconListArray = [
            {
              icon: TemperatureIcon,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: PalletsCompletedIcon,
              value: `${data?.packagesCompleted}%`,
              label: "Pallets Completed",
            },
            {
              icon: DaysMaintanenceIcon,
              value: `${data?.daysMaintanence}`,
              label: "Days Untill Maintanence",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;
        case "amr":
          iconListArray = [
            {
              icon: TemperatureIcon,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: TripsRemainingIcon,
              value: `${data?.tripsRemaining}`,
              label: "Trips Remaining",
            },
            {
              icon: OprHrsIcon,
              value: `${data?.oprHours}Hrs`,
              label: "Opr. Hrs",
            },
            {
              icon: ChargeIcon,
              value: `${data?.charge}%`,
              label: "Charge",
            },
          ];
          break;

        case "equipment":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: HumidityIcon,
              value: `${data?.humidity}%`,
              label: "Humidity",
            },
            {
              icon: PressureIcon,
              value: `${data?.pressure}hPa`,
              label: "Pressure",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;

        case "equipment-2":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: HumidityIcon,
              value: `${data?.humidity}%`,
              label: "Humidity",
            },
            {
              icon: PressureIcon,
              value: `${data?.pressure}hPa`,
              label: "Pressure",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;

        case "equipment-3":
          iconListArray = [
            {
              icon: TemperatureIcon2,
              value: `${data?.temparature}°C`,
              label: "Temperature",
            },
            {
              icon: HumidityIcon,
              value: `${data?.humidity}%`,
              label: "Humidity",
            },
            {
              icon: PressureIcon,
              value: `${data?.pressure}hPa`,
              label: "Pressure",
            },
            {
              icon: VibrationIcon,
              value: `${data?.vibration}mm`,
              label: "Vibration",
            },
          ];
          break;

        case "workers":
          iconListArray = [
            {
              icon: SafetyInfractionsIcon,
              value: `${data?.safetyInfration}`,
              label: "Safety Infractions",
            },
            {
              icon: OperationalHoursIcon,
              value: `${data?.oprHours}Hrs`,
              label: "Operation Hours",
            },
            {
              icon: SafetyScoreIcon,
              value: `${data?.safetyScore}%`,
              label: "Safety Score",
            },
            {
              icon: ProductivityScoreIcon,
              value: `${data?.productivityScore}%`,
              label: "Productivity Score",
            },
          ];
          break;
        default:
          break;
      }
      setListItemIconArray(iconListArray);
    }
  }, [selectedNotification]);

  return (
    <>
      <div
        className={listItemContainer}
        onClick={() => handleExpandListItem(index, id, currentTimeStamp)}
        ref={refs[id]}
      >
        {selectedNotification === id ? (
          <div className={expandedListItem}>
            <div className={listItemTitle}>{title}</div>
            <div className={listItemLocation}>
              <img
                src={LocationIcon}
                alt="location Icon"
                className={locationIconStyle}
                width={16}
              />
              {area}
            </div>
            <div className={expandedListIconContainer}>
              {listItemIconArray &&
                listItemIconArray.length > 0 &&
                listItemIconArray?.map((item: any, index: number) => {
                  return (
                    <div className={listItemSection} key={index}>
                      {" "}
                      <div className={listItemIconDetails}>{item?.value}</div>
                      <div className={listItemIconName}>{item?.label}</div>
                    </div>
                  );
                })}
            </div>
            <div className={listItemFooterStyle}>
              <Button
                variant="contained"
                handleClick={(event: any) =>
                  handleViewDialogue(id, event, index)
                }
                buttonStyles={selectedButtonStyles}
              >
                {itemListButton}
              </Button>

              <span className={listItemTimeStyle}>{notificationTimeStamp}</span>
            </div>
          </div>
        ) : (
          <div className={collapsedListItem}>
            <div className={listItemTitle}>{title}</div>
            <div className={listItemLocation}>{area}</div>
          </div>
        )}
      </div>
    </>
  );
};
export default DashboardListItems;
