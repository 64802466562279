import FloorMapMain from './floormap-main.svg';
import box from './box.svg';
import camera from './camera.svg';
import cluster from './cluster.svg';
import conveyerBelt from './conveyer-belt.svg';
import forklift from './forklift.svg';
import fullscreen from './fullscreen.svg';
import robotArm from './robot-arm.svg';
import worker from './worker.svg';

export {
    box,
    camera,
    cluster,
    conveyerBelt,
    forklift,
    fullscreen,
    robotArm,
    worker,
}
export default FloorMapMain