import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import AnalyticsContainer from "components/AnalyticsContainer";
import theme from "../../theme/theme";

const Analytics = () => {
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AnalyticsContainer />
        </Grid>
      </Grid>
    </>
  );
};
export default Analytics;