import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  rootContainer: (props: any) => ({
    marginTop: "84px",
    [muiTheme.breakpoints.down(801)]: {
      marginTop: 98,
    },
    [muiTheme.breakpoints.down(1335)]: {
      marginTop: 50,
    },
  }),

  notificationList: (props: any) => ({
    background: "#F2F5F9",
  }),
}));

export default useStyles;
