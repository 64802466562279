import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import ChevronDownIcon from "../../assets/chevron-down-icon.svg";
import ChevronUpIcon from "../../assets/chevron-up-icon.svg";

const NumberField = (props: any) => {
  const { value, symbol, minRange, maxRange, handleInputChange, disabled } =
    props;
  const format = (val: any) => `${symbol}` + val;
  const parse = (val: any) => val.replace(/^\$/, "");

  return (
    <div>
      <NumberInput
        step={1}
        value={format(value)}
        min={minRange}
        max={maxRange}
        isDisabled={disabled}
        onChange={(valueString) => handleInputChange(parse(valueString))}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper children={<img src={ChevronDownIcon} />} />
          <NumberDecrementStepper children={<img src={ChevronUpIcon} />} />
        </NumberInputStepper>
      </NumberInput>
    </div>
  );
};

export default NumberField;
