import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  dashboardSection: (props: any) => ({
    marginTop: 84,
    [muiTheme.breakpoints.down(801)]: {
      marginTop: 98,
    },
    [muiTheme.breakpoints.down(1335)]: {
      marginTop: 50,
    },
  }),
});
export default useStyles;
