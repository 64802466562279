const userList = [
  {
    userName: "robertking@schneider.com",
    userId: 3,
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    email: "robertking@schneider.com",
    currentRoleType: "ADMIN",
    firstName: "Robert",
    lastName: "King",
    password: "RobertKing2024#",
  },
  {
    userName: "mikeross@qualcomm.com",
    userId: 3,
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    email: "mikeross@qualcomm.com",
    currentRoleType: "ADMIN",
    firstName: "Mike",
    lastName: "Ross",
    password: "Mike@1234",
  },
  {
    userName: "jessica@qualcomm.com",
    userId: 3,
    email: "jessica@qualcomm.com",
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    currentRoleType: "ADMIN",
    firstName: "Jessica",
    lastName: "",
    password: "Jessica@2023",
  },
  {
    userName: "louislitt@qualcomm.com",
    userId: 3,
    email: "louislitt@qualcomm.com",
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    currentRoleType: "ADMIN",
    firstName: "Louis",
    lastName: "Litt",
    password: "Louis@2023",
  },
  {
    userName: "emmapalmer@qualcomm.com",
    email: "Emmapalmer@qualcomm.com",
    userId: 3,
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    currentRoleType: "ADMIN",
    firstName: "Emma",
    lastName: "Palmer",
    password: "Emma@2023",
  },
  {
    userName: "harveyspecter@qualcomm.com",
    email: "Harveyspecter@qualcomm.com",
    userId: 3,
    loginTime: "2022-11-21T10:49:58.088+00:00",
    roles: ["Admin"],
    phoneNum: "+15852826892",
    currentRoleType: "ADMIN",
    firstName: "Harvey",
    lastName: "Specter",
    password: "Harvey@2023",
  },
];

export default userList;
