import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import FloorMap from "../FloorMap";
import useTranslation from "../../localization/translations";
import InfoIcon from "../../elements/InfoIcon";
import AlertIcon from "../../assets/InfoIcon/alert.svg";
import ClockIcon from "../../assets/InfoIcon/clock.svg";
import EnergyIcon from "../../assets/InfoIcon/energy.svg";
import PalletsIcon from "../../assets/InfoIcon/pallets.svg";
import WorkersIcon from "../../assets/InfoIcon/workers.svg";
import PowerBackupIcon from "../../assets/InfoIcon/powerBackup.svg";
import ProductionIcon from "../../assets/InfoIcon/production.svg";
import useStyles from "./styles";

const DashboardMap: React.FC<any> = (props) => {
  const {
    dashboardData,
    dashboardMainList: {
      pallets,
      energyConsumed,
      backupPower,
      productionEfficiency,
      operationHours,
      workers,
      safetyInfractions,
    },
    selectedMarker,
    setSelectedMarker,
    setSelectedNotification,
    selectedNotification,
    setShowFullscreenMap,
    showFullscreenMap,
    notificationTimeStamp,
    handleViewDialogue,
    viewDialogueIsOpen
  } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { infoIconContainer, dashboardMapContainer } = useStyles(appTheme);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    infoIconTitle1,
    infoIconTitle2,
    infoIconTitle3,
    infoIconTitle4,
    infoIconTitle5,
    infoIconTitle6,
    infoIconTitle7,
  } = useTranslation();

  const InfoIconList = [
    {
      color: appTheme?.palette?.dashboardList?.lightPurple1,
      title: pallets,
      tagLine: infoIconTitle1,
      icon: PalletsIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.lightBlue3,
      title: energyConsumed,
      tagLine: infoIconTitle2,
      icon: EnergyIcon,
    },
    {
      color: "#62ACFF",
      title: backupPower,
      tagLine: infoIconTitle3,
      icon: PowerBackupIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.orange,
      title: productionEfficiency,
      tagLine: infoIconTitle4,
      icon: ProductionIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.greenishBlue,
      title: operationHours,
      tagLine: infoIconTitle5,
      icon: ClockIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.blue1,
      title: workers,
      tagLine: infoIconTitle6,
      icon: WorkersIcon,
    },
    {
      color: appTheme?.palette?.dashboardList?.darkPink,
      title: safetyInfractions,
      tagLine: infoIconTitle7,
      icon: AlertIcon,
    },
  ];

  return (
    <div className={dashboardMapContainer}>
      <div>
        <FloorMap
          floorWidth={1379}
          floorHeight={865}
          markers={dashboardData}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          setShowFullscreenMap={setShowFullscreenMap}
          showFullscreenMap={showFullscreenMap}
          notificationTimeStamp={notificationTimeStamp}
          defaultMap={true}
          handleViewDialogue={handleViewDialogue}
          viewDialogueIsOpen={viewDialogueIsOpen}
        />
      </div>
      {!showFullscreenMap ? (
        <div className={infoIconContainer}>
          {InfoIconList &&
            InfoIconList?.map((value, index) => {
              return (
                <InfoIcon
                  color={value?.color}
                  title={value?.title}
                  tagLine={value?.tagLine}
                  icon={value?.icon}
                  fabWidth={45}
                  fabHeight={45}
                  imgWidth={25}
                  imgHeight={25}
                  key={index}
                />
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
export default DashboardMap;
