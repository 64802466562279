import React, { Fragment, useEffect, useState } from "react";
import SearchBox from "elements/SearchBox";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import theme from "../../theme/theme";
import useStyles from "./styles";

const AnalyticsList: React.FC<any> = (props) => {

  const {
    analyticsListsItems,
     handleSelectedAnalyticList,
     activeListItemIndex,
     setActiveListItemIndex,
    }=props;
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    rootContainer,
    analyticsContainerHeader,
    searchBoxSection,
    searchClass,
    redButtonStyle,
    redButtonStyleInactive,
    analyticsNotificationListSection,
    analyticsNotificationListItemsActive,
    analyticsNotificationListItems,
    

  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );



  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  const handleSearchIsOpen = () => {
    setSearchIsOpen(!searchIsOpen);
    handleSearch("");
    setActiveListItemIndex(null);
  };

  const [newSearchValue, setNewSearchValue] = useState<any>(analyticsListsItems);

  const handleSearch = (searchValue: string) => {
    let filteredData =
      analyticsListsItems &&
      analyticsListsItems?.filter((notification: any) => {
        

        return (
          notification.title
            .toLowerCase()
            .includes(searchValue?.toLowerCase()) 
        );
      });
    setNewSearchValue(filteredData); 
    setActiveListItemIndex(null);
  
  };

 
  

  const handleLeftPanelList = (item : any, index : number) => {
    handleSelectedAnalyticList(item);    
    setActiveListItemIndex(index);
  };

  const handleOverallAnalytics = (selectedOverAll : any) => {
    // setSelectedLeftPanelList("overAll");
    handleSelectedAnalyticList(selectedOverAll)
    setActiveListItemIndex(null);
  };

  useEffect(()=>{
if(activeListItemIndex === null){

  handleSelectedAnalyticList(analyticsListsItems && analyticsListsItems[0])
}
  },[activeListItemIndex])

  return (
    <Fragment>
    
              <div className={analyticsContainerHeader}>
                <div className={searchBoxSection}>
                {searchIsOpen ?
                <div>
              <SearchBox
                        searchInput={searchClass}
                        placeHolder={"Search"}
                        handleSearch={handleSearch}
                        searchIsOpen={searchIsOpen}
                        fontColor={"#000"}
                      />
              </div>
              :
              <span>Equipment</span>
              }
                  
                </div>
                <div>
                <img src={searchIsOpen ? CloseIcon : SearchIcon} onClick={handleSearchIsOpen}></img>
                  
                </div>
                <div style={{marginLeft:"10px"}}>
                <p
                    onClick={()=>handleOverallAnalytics(newSearchValue[0])}
                    className={
                      activeListItemIndex === null
                        ? redButtonStyle
                        : 
                        redButtonStyleInactive
                    }
                  >
                    {}
                    OVERALL ANALYTICS
                  </p>
                </div>
                
              </div>
              
              
              <div
                className={analyticsNotificationListSection}
                style={{
                  height: "calc(100vh - 180px)"
                    // : "calc(100vh - 248px)",
                }}
              >
                {newSearchValue &&
                  newSearchValue.length > 0 &&
                  newSearchValue.map((item : any, index : number) => {
                    
                    return (
                      <>
                        <div >
                        {item.title === "Overall Analytics" ? null : (
                            <div
                              key={index}
                              className={
                                activeListItemIndex === index
                                  ? analyticsNotificationListItemsActive
                                  : analyticsNotificationListItems
                              }
                              onClick={() => 
                                handleLeftPanelList(item, index)                        
                              }
                            >
                              {item.title}
                            </div>
                        )}
                        
                        </div>
                      </>
                    );
                  })}
              </div>
           
    </Fragment>
  );
};

export default AnalyticsList;
