import { useState, useEffect, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Slider } from "@mui/material";
import { Fullscreen, FullscreenExit, ZoomIn } from "@mui/icons-material/";
import FloorMapMain from "assets/floormap";
import Icon from "components/Icon";
import Tooltip from "elements/Tooltip";
import AlertButton from "../../assets/alertsButton.svg";
import CallButton from "../../assets/callButton.svg";
import DeleteButton from "../../assets/deleteButton.svg";
import TemperatureIcon from "../../assets/listItemIcons/temperature.svg";
import TemperatureIcon2 from "../../assets/listItemIcons/temperature2.svg";
import BeltSpeedIcon from "../../assets/listItemIcons/beltSpeed.svg";
import ChargeIcon from "../../assets/listItemIcons/charge.svg";
import DaysMaintanenceIcon from "../../assets/listItemIcons/daysMaintanence.svg";
import HumidityIcon from "../../assets/listItemIcons/humidity.svg";
import OperationalHoursIcon from "../../assets/listItemIcons/operationalHours.svg";
import OprHrsIcon from "../../assets/listItemIcons/oprHrs.svg";
import PackagesCompletedIcon from "../../assets/listItemIcons/packagesCompleted.svg";
import PalletsCompletedIcon from "../../assets/listItemIcons/palletsCompleted.svg";
import PressureIcon from "../../assets/listItemIcons/pressure.svg";
import ProductivityScoreIcon from "../../assets/listItemIcons/productivityScore.svg";
import RejectsIcon from "../../assets/listItemIcons/rejects.svg";
import SafetyInfractionsIcon from "../../assets/listItemIcons/safetyInfractions.svg";
import SafetyScoreIcon from "../../assets/listItemIcons/safetyScore.svg";
import TensionIcon from "../../assets/listItemIcons/tension.svg";
import TripsRemainingIcon from "../../assets/listItemIcons/tripsRemaining.svg";
import VibrationIcon from "../../assets/listItemIcons/vibration.svg";
import ChargingIcon from "assets/charging-icon.svg";
import LocationIcon from "../../assets/locationIcon.svg";

import {
  RootContainer,
  MapWrapper,
  ToolBarContainer,
  MapImage,
  MarkerContainer,
  Marker,
  MarkerCallout,
  CalloutContent,
  CalloutTitle,
  CalloutSubtitle,
  CalloutBody,
  CalloutBodyContainer,
  CalloutBodyIconContainer,
  CalloutBodyLabelContainer,
  CalloutFooter,
  Divider,
  DetailsButton,
  DateText,
  ToolIconWrapper,
  ToolIconContainer,
  CalloutBodyIcon,
  CalloutAlertText,
  CalloutHeaderContainer,
  CalloutHeaderSection,
  CalloutHeaderAlertButtonContainer,
  CalloutHeaderAlertButton,
  AlertEquipmentName,
  CalloutCloseButton,
  VerticalSpace,
  MarkerCloseButtonContainer,
  MarkerBadge,
} from "./styles";

type MarkerProps = {
  category: string;
  id: number;
  index?: number;
  title: string;
  subTitle?: string;
  area: string;
  temparature: string;
  humidity: string;
  oprHours: string;
  powerUsage: string;
  currentTimeStamp: string;
  type?: string;
  rejects?: any;
  beltSpeed?: any;
  tension?: any;
  palletsCompleted?: any;
  daysMaintanence?: any;
  tripsRemaining?: any;
  charge?: any;
  pressure?: any;
  vibration?: any;
  safetyInfration?: any;
  safetyScore?: any;
  productivityScore?: any;
  packagesCompleted?: any;
  inCharging?: boolean;
  location: {
    x: number;
    y: number;
  };
};

type FloorMapProps = {
  floorWidth: number;
  floorHeight: number;
  markers: MarkerProps[];
  defaultMap?: boolean;
  selectedMarker?: number | undefined;
  setSelectedMarker: (arg0: number) => void;
  setSelectedNotification: (arg0: number) => void;
  selectedNotification?: number | undefined;
  setShowFullscreenMap: any;
  showFullscreenMap: any;
  notificationTimeStamp: any;
  dateTimeFormat?: any;
  tabIndex?: number;
  setTabIndex?: (arg0: number) => void;
  handleViewDialogue?: any;
  viewDialogueIsOpen?: (arg0: boolean) => void;
};

type MarkerSpecs = {
  position: {
    x: number;
    y: number;
  };
};

const FloorMap = (props: FloorMapProps) => {
  const {
    floorWidth,
    floorHeight,
    markers,
    defaultMap,
    selectedMarker,
    setSelectedMarker,
    setSelectedNotification,
    selectedNotification,
    showFullscreenMap,
    setShowFullscreenMap,
    notificationTimeStamp,
    dateTimeFormat,
    tabIndex,
    setTabIndex,
    handleViewDialogue,
    viewDialogueIsOpen,
  } = props;

  const floorMapRef = useRef(null);
  const wrapperRef = useRef<any>(null);

  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [isFloormapMounted, setIsFloormapMounted] = useState(false);
  const [currentMapWidth, setCurrentMapWidth] = useState(0);
  const [currentMapHeight, setCurrentMapHeight] = useState(0);
  const [calloutContent, setCalloutContent] = useState<any>({});
  const [isFullscreenPopupOpen, setIsFullscreenPopupOpen] = useState(false);
  const [showZoomSlider, setShowZoomSlider] = useState(false);
  const [listItemIconArray, setListItemIconArray] = useState<any>([]);
  const [popperTimeStamp, setPopperTimeStamp] = useState();

  const OnScreenResize = () => {
    if (floorMapRef?.current) {
      const { width, height } = floorMapRef?.current;
      wrapperRef?.current?.centerView();
      setCurrentMapWidth(width);
      setCurrentMapHeight(height);
    }
  };

  const getMarkerColor = (category: string, type: string | undefined) => {
    switch (type) {
      case "events":
        return "#38BCDB";
      case "alerts":
        return "#F54840";
      case "operations":
        return "#E0791A";
      default: {
        if (category === "workers") return "#315B35";
        return "#59B974";
      }
    }
  };

  const getMarkerSpec = ({ position }: MarkerSpecs) => {
    let x = 0,
      y = 0;
    if (position?.x && position?.y) {
      x = (position.x / floorWidth) * currentMapWidth;
      y = (position.y / floorHeight) * currentMapHeight;
    }
    return { x, y };
  };

  const onMapClick = (evt: any) => {
    if (evt.target !== evt.currentTarget) return;
  };

  const onZoom = (evt: any) => {
    const { zoomIn, zoomOut, setTransform } = wrapperRef?.current;

    setCurrentZoomLevel(evt.target.value);
    let isZoomingOut = false;
    const targetScale = parseFloat(evt.target.value);
    const factor = Math.log(targetScale / currentZoomLevel);

    if (targetScale > currentZoomLevel) {
      zoomIn(factor, 0);
    } else {
      zoomOut(-factor, 0);
      isZoomingOut = true;
    }
  };

  const getCalloutWindowBody = (marker: any) => {
    let iconListArray: any = [];
    if (!marker) {
      return [];
    }
    switch (marker?.category) {
      case "forklift":
        iconListArray = [
          {
            icon: TemperatureIcon,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: TripsRemainingIcon,
            value: `${marker?.tripsRemaining}`,
            label: "Trips Remaining",
          },
          {
            icon: OprHrsIcon,
            value: `${marker?.oprHours}Hrs`,
            label: "Opr. Hrs",
          },
          {
            icon: ChargeIcon,
            value: `${marker?.charge}%`,
            label: "Charge",
          },
        ];
        break;
      case "aiCamera":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: HumidityIcon,
            value: `${marker?.humidity}%`,
            label: "Humidity",
          },
          {
            icon: RejectsIcon,
            value: `${marker?.rejects}`,
            label: "Rejects",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "robotArm":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: HumidityIcon,
            value: `${marker?.humidity}%`,
            label: "Humidity",
          },
          {
            icon: DaysMaintanenceIcon,
            value: `${marker?.daysMaintanence}`,
            label: "Days Until Maintenance",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "conveyerBelt":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: BeltSpeedIcon,
            value: `${marker?.beltSpeed}rpm`,
            label: "Belt Speed",
          },
          {
            icon: TensionIcon,
            value: `${marker?.tension}N/mm`,
            label: "Tension",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "pallet":
        iconListArray = [
          {
            icon: TemperatureIcon,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: PalletsCompletedIcon,
            value: `${marker?.palletsCompleted}%`,
            label: "Pallets Completed",
          },
          {
            icon: DaysMaintanenceIcon,
            value: `${marker?.daysMaintanence}`,
            label: "Days Untill Maintanence",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "packager":
        iconListArray = [
          {
            icon: TemperatureIcon,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: PalletsCompletedIcon,
            value: `${marker?.packagesCompleted}%`,
            label: "Pallets Completed",
          },
          {
            icon: DaysMaintanenceIcon,
            value: `${marker?.daysMaintanence}`,
            label: "Days Untill Maintanence",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "amr":
        iconListArray = [
          {
            icon: TemperatureIcon,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: TripsRemainingIcon,
            value: `${marker?.tripsRemaining}`,
            label: "Trips Remaining",
          },
          {
            icon: OprHrsIcon,
            value: `${marker?.oprHours}Hrs`,
            label: "Opr. Hrs",
          },
          {
            icon: ChargeIcon,
            value: `${marker?.charge}%`,
            label: "Charge",
          },
        ];
        break;

      case "equipment":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: HumidityIcon,
            value: `${marker?.humidity}%`,
            label: "Humidity",
          },
          {
            icon: PressureIcon,
            value: `${marker?.pressure}hPa`,
            label: "Pressure",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "equipment-2":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: HumidityIcon,
            value: `${marker?.humidity}%`,
            label: "Humidity",
          },
          {
            icon: PressureIcon,
            value: `${marker?.pressure}hPa`,
            label: "Pressure",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;
      case "equipment-3":
        iconListArray = [
          {
            icon: TemperatureIcon2,
            value: `${marker?.temparature}°C`,
            label: "Temperature",
          },
          {
            icon: HumidityIcon,
            value: `${marker?.humidity}%`,
            label: "Humidity",
          },
          {
            icon: PressureIcon,
            value: `${marker?.pressure}hPa`,
            label: "Pressure",
          },
          {
            icon: VibrationIcon,
            value: `${marker?.vibration}mm`,
            label: "Vibration",
          },
        ];
        break;

      case "workers":
        iconListArray = [
          {
            icon: SafetyInfractionsIcon,
            value: `${marker?.safetyInfration}`,
            label: "Safety Infractions",
          },
          {
            icon: OperationalHoursIcon,
            value: `${marker?.oprHours}Hrs`,
            label: "Operation Hours",
          },
          {
            icon: SafetyScoreIcon,
            value: `${marker?.safetyScore}%`,
            label: "Safety Score",
          },
          {
            icon: ProductivityScoreIcon,
            value: `${marker?.productivityScore}%`,
            label: "Productivity Score",
          },
        ];
        break;
      default:
        break;
    }
    return iconListArray || [];
  };

  const renderCallout = () => {
    const { x, y } = getMarkerSpec({ position: calloutContent?.location });
    const placement = x >= currentMapWidth / 2 ? "left" : "right";
    let verticalPlacement = "center";

    if (y <= currentMapHeight / 4) {
      verticalPlacement = "bottom";
    } else if (
      calloutContent?.location?.y >=
      currentMapHeight - currentMapHeight / 5
    ) {
      verticalPlacement = "top";
    }


    if (!defaultMap) {
      return (
        <MarkerCallout
          x={x}
          y={y}
          currentScale={1 / currentZoomLevel}
          placement={placement}
          verticalPlacement={verticalPlacement}
        >
          <MarkerCloseButtonContainer>
            <CalloutCloseButton
              onClick={() => handleMarkerClick(calloutContent)}
            >
              x
            </CalloutCloseButton>
          </MarkerCloseButtonContainer>
          <CalloutContent>
            <CalloutHeaderContainer>
              <CalloutHeaderSection>
                <CalloutTitle>{calloutContent?.title}</CalloutTitle>
                <CalloutAlertText>{calloutContent?.subTitle}</CalloutAlertText>
              </CalloutHeaderSection>
              <CalloutHeaderAlertButtonContainer>
                <Tooltip tooltipValue={"Raise alert"}>
                  <CalloutHeaderAlertButton src={AlertButton} />
                </Tooltip>
                <Tooltip tooltipValue={"Call"}>
                  <CalloutHeaderAlertButton src={CallButton} />
                </Tooltip>
                <Tooltip tooltipValue={"Delete"}>
                  <CalloutHeaderAlertButton src={DeleteButton} />
                </Tooltip>
              </CalloutHeaderAlertButtonContainer>
            </CalloutHeaderContainer>
            <CalloutSubtitle>
              <Icon icon="location" size={17} className="mr-8" />
              {calloutContent?.area}
            </CalloutSubtitle>
            <VerticalSpace count={2} />
            <Divider />
            <CalloutFooter>
              <AlertEquipmentName>{calloutContent?.name}</AlertEquipmentName>
              <DateText>{notificationTimeStamp}</DateText>
            </CalloutFooter>
          </CalloutContent>
        </MarkerCallout>
      );
    }
    return (
      <MarkerCallout
        x={x}
        y={y}
        currentScale={1 / currentZoomLevel}
        placement={placement}
        verticalPlacement={verticalPlacement}
      >
        <MarkerCloseButtonContainer>
          <CalloutCloseButton onClick={() => handleMarkerClick(calloutContent)}>
            x
          </CalloutCloseButton>
        </MarkerCloseButtonContainer>
        <CalloutContent>
          <CalloutTitle>{calloutContent?.title}</CalloutTitle>
          <CalloutSubtitle>
            {/* For this deliver commented the CIGIA logic */}
            {/* <Icon icon="location" size={17} className="mr-8" /> */}
            <img
                src={LocationIcon}
                alt="location Icon"
                style={{margin: "0 10px 0 0",}}
                width={16}
              />
            {calloutContent?.area}
          </CalloutSubtitle>
          <CalloutBody>
            {getCalloutWindowBody(calloutContent).map((markerContent: any) => {
              return (
                <CalloutBodyContainer>
                  <CalloutBodyIconContainer>
                    <span>{markerContent?.value}</span>
                  </CalloutBodyIconContainer>
                  <CalloutBodyLabelContainer>
                    {markerContent?.label}
                  </CalloutBodyLabelContainer>
                </CalloutBodyContainer>
              );
            })}
          </CalloutBody>
          <Divider />
          <CalloutFooter>
            <DetailsButton
              onClick={(evt: any) =>
                handleViewDialogue(
                  calloutContent?.id,
                  evt,
                  calloutContent?.indexVal
                )
              }
            >
              View Detail
            </DetailsButton>
            <DateText>
              {showFullscreenMap ? popperTimeStamp : notificationTimeStamp}
            </DateText>
          </CalloutFooter>
        </CalloutContent>
      </MarkerCallout>
    );
  };

  const switchTabs = (type: string) => {
    switch (type) {
      case "events": {
        setTabIndex && setTabIndex(0);
        break;
      }
      case "alerts": {
        setTabIndex && setTabIndex(1);
        break;
      }
      case "operations": {
        setTabIndex && setTabIndex(2);
        break;
      }
      default: {
        break;
      }
    }
  };

  const calculateAspectRationDifference = () => {
    const floormapDimensions = {
      width: currentMapWidth,
      height: currentMapHeight,
    };
    const containerDimensions = {
      width: wrapperRef?.current?.instance?.wrapperComponent?.clientWidth,
      height: wrapperRef?.current?.instance?.wrapperComponent?.clientHeight,
    };

    // if(floormapDimensions.height > containerDimensions.height) return "height"

    return containerDimensions.height;
  };

  const handleMarkerClick = (marker: any) => {
    setSelectedMarker(
      selectedNotification === marker.id ||
        selectedNotification === marker.index
        ? -1
        : defaultMap
        ? marker.id
        : marker.index
    );
    setCalloutContent(marker);
    setPopperTimeStamp(marker.currentTimeStamp);
    if (!defaultMap) switchTabs(marker?.type);
  };

  useEffect(() => {
    window.addEventListener("resize", OnScreenResize);
    setInterval(() => OnScreenResize(), 1000);
   
    return () => {
      window.removeEventListener("resize", OnScreenResize);
    };
  }, [floorMapRef?.current, isFloormapMounted]);

  useEffect(() => {
    const currentSelectedNotification = markers.filter(
      (marker) =>
        marker.id === selectedNotification ||
        marker.index === selectedNotification
    )[0];
    setCalloutContent(currentSelectedNotification);
  }, [selectedNotification]);

  useEffect(() => {
    setSelectedMarker(-1);
  }, [showFullscreenMap]);

  return (
    <RootContainer>
      <ToolBarContainer>
        <ToolIconWrapper>
          <ToolIconContainer>
            {showZoomSlider ? (
              <Slider
                aria-label="Temperature"
                orientation="vertical"
                valueLabelDisplay="auto"
                defaultValue={1}
                sx={{
                  position: "absolute",
                  bottom: "100%",
                  marginBottom: "20px",
                }}
                min={1}
                max={8}
                onChange={onZoom}
                value={currentZoomLevel}
              />
            ) : null}
            <ZoomIn
              onClick={() => setShowZoomSlider((prev) => !prev)}
              sx={{ color: "#FFF" }}
            />
          </ToolIconContainer>
        </ToolIconWrapper>
        <ToolIconWrapper>
          <ToolIconContainer
            onClick={() => setShowFullscreenMap((prev: boolean) => !prev)}
          >
            {showFullscreenMap ? (
              <FullscreenExit sx={{ color: "#FFF" }} />
            ) : (
              <Fullscreen sx={{ color: "#FFF" }} />
            )}
          </ToolIconContainer>
        </ToolIconWrapper>
      </ToolBarContainer>
      <TransformWrapper
        onTransformed={(ref, props) => setCurrentZoomLevel(props.scale)}
        ref={wrapperRef}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <MapWrapper onClick={onMapClick}>
            <TransformComponent wrapperStyle={{ width: "100%" }}>
              <>
                {/* constantDimension={currentMapHeight >= window.innerHeight - 200 ? 'height'} */}
                <MapImage
                  src={FloorMapMain}
                  alt="test"
                  ref={floorMapRef}
                  constantSide={calculateAspectRationDifference()}
                  onLoad={() => setIsFloormapMounted(true)}
                />
                {markers?.length &&
                  markers.map((marker) => {
                    const { x, y } = getMarkerSpec({
                      position: marker?.location,
                    });

                    if (!marker?.category) {
                      return null;
                    }
                    return (
                      <MarkerContainer
                        y={y}
                        x={x}
                        currentScale={1 / currentZoomLevel}
                        key={marker?.id}
                      >
                        {marker?.inCharging && (
                          <MarkerBadge>
                            <img src={ChargingIcon} />
                          </MarkerBadge>
                        )}
                        <Marker
                          onClick={() => handleMarkerClick(marker)}
                          key={marker?.id}
                          icon={marker?.category}
                          size={50}
                          color={getMarkerColor(
                            marker?.category,
                            !defaultMap ? marker?.type : undefined
                          )}
                        />
                      </MarkerContainer>
                    );
                  })}
                {selectedMarker !== -1 ? renderCallout() : null}
              </>
            </TransformComponent>
          </MapWrapper>
        )}
      </TransformWrapper>
    </RootContainer>
  );
};

export default FloorMap;
