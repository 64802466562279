import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles(() => ({
  rootContainer: (props: any) => ({
    // marginTop: "84px",
  }),

  infoIconContainer: (props: any) => ({
    // position: "absolute",
    bottom: 0,
    background: "#F3F6FF",
    margin: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-around",
    width: "calc(100% - 20px)",
    [muiTheme.breakpoints.down(801)]: {
      overflow: " auto",
    },
    // backdropFilter: "blur(7.5px)",
  }),

  analyticsGridSection: (props: any) => ({
    margin: "0px 10px 5px 5px",
    // display: "flex",
    // flexDirection: "column",
    height: "calc(100vh - 300px) !important",
    // [theme.breakpoints.down(1537)]: {

    //   height: "calc(100vh - 271px) !important",
    //   overflow: "auto",
    // },
    // [theme.breakpoints.down(900)]: {
    //   marginBottom: 45,
    // },
  }),

  flex1: (props: any) => ({
    flex: 1,
  }),

  analyticsGrid: (props: any) => ({
    display: "flex",
    flexDirection: "column",
  }),

  graphSection: (props: any) => ({
    background: "#F5F7FF",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 12px",
    margin: 5,
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  }),

  incomeText: (props: any) => ({
    fontFamily: "QualcommNext-Regular",
    fontSize: 16,
    lineHeight: "22px",
    // color: theme.palette.blue,
    marginRight: 16,
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 12,
    },
    [muiTheme.breakpoints.down(1280)]: {
      fontSize: 9,
    },
  }),

  incomeCurrentSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    width: "100%",
  }),

  customSelect: (props: any) => ({
    minWidth: "100px !important",
    minheight: "5px !important",

    "& .MuiSelect-select": {
      padding: "10px 11px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "50px",
      background: "#fff !important",
      fontSize: 14,
      // lineHeight: 21,
      textAlign: "center",
      fontWeight: "bold",
      position: "relative",
      color: "#000",
      "& .MuiSvgIcon-root": {
        position: "absolute",
        right: "10px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        color: "#000",
        border: "1px solid #95B8D4 !important",
      },
    },

    "& .MuiSelect-iconOutlined": {
      color: "#000 !important",
    },
  }),
}));

export default useStyles;
