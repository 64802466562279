import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    padding: "40px 110px 40px 110px",
    background: "#ffffff",
    height: "calc(100vh - 135px)",
    overflow: "auto",
    [muiTheme.breakpoints.up(1537)]: {
      padding: "10px 64px",
    },
    [muiTheme.breakpoints.down(1537)]: {
      padding: "10px 18px",
    },
    [muiTheme.breakpoints.down(1335)]: {
      height: "calc(100vh - 99px)",
    },
    [muiTheme.breakpoints.down(1025)]: {
      padding: 6,
    },
  }),
  titleSection: (props: any) => ({
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  }),
  subListContainer: (props: any) => ({
    borderRadius: "5px",
    marginTop: "20px",
    border: "0.5px solid #52B667",
    background: "#FDFDFB !important",
  }),
  subListItemContainer: (props: any) => ({
    position: "relative",
    padding: "10px 70px 30px 50px",
    [muiTheme.breakpoints.up(1537)]: {
      padding: "10px 26px",
    },
    [muiTheme.breakpoints.down(1537)]: {
      padding: 10,
    },
    [muiTheme.breakpoints.down(1025)]: {
      padding: 6,
    },
  }),
  listItem: (props: any) => ({
    display: "flex",
    alignItems: "baseline",
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: "10px",
      lineHeight: "15px",
      marginRight: "6px",
    },
  }),
  listItemSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: "12px",
    justifyContent: "space-between",
  }),
  inputSection: (props: any) => ({
    paddingRight: "20px",
    width: "100%",
    "& input": {
      background: "#FFFFFF",
      border: "0.5px solid #D1D1D1",
      borderRadius: "50px",
      fontSize: " 15px",
      lineHeight: "30px",
      color: "#707070",
      fontFamily: "QualcommNext-Medium",
      width: "100%",
      padding: "8px 1px 8px 10px",
      [muiTheme.breakpoints.down(1537)]: {
        fontSize: "10px",
        lineHeight: "15px",
      },
    },
    "& img": {
      [muiTheme.breakpoints.down(1025)]: {
        width: "10px",
      },
    },
  }),
  updateCustomButton: (props: any) => ({
    [muiTheme.breakpoints.down(801)]: {
      position: "absolute",
      top: "0",
      right: "0",
      margin: "4px 12px 0 12px",
    },
    "& .MuiButtonBase-root": {
      background: "#F0F2FB",
      border: "1px solid #52B667 !important",
      borderRadius: "20px",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#52B667",
      fontFamily: `"QualcommNext-Regular"`,
      padding: " 8px 12px",
      [muiTheme.breakpoints.down(801)]: {
        padding: "4px 12px",
      },
      "& span": {
        fontSize: "16px",
      },
      [muiTheme.breakpoints.down(1025)]: {
        fontSize: "8px",
        "& span": {
          fontSize: "10px",
        },
      },
    },
  }),
  radioButtonSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiRadio-root": {
      padding: "0 4px 0 0",
      "&.Mui-checked": {
        color: props?.palette?.login?.loginButton,
      },
    },
    "& .MuiCheckbox-root": {
      padding: "0 4px 0 0",
      "&.Mui-checked": {
        color: props?.palette?.login?.loginButton,
      },
    },
    "& .MuiFormControlLabel-root": {
      [muiTheme.breakpoints.up(1920)]: {
        marginRight: 68,
      },
      [muiTheme.breakpoints.up(1680)]: {
        marginRight: 48,
      },
      [muiTheme.breakpoints.down(1680)]: {
        marginRight: 36,
      },
      [muiTheme.breakpoints.down(1281)]: {
        marginRight: 26,
      },
      [muiTheme.breakpoints.down(1025)]: {
        marginRight: 26,
      },
      [muiTheme.breakpoints.down(801)]: {
        marginRight: 16,
      },

      "& .MuiTypography-root": {
        fontFamily: "QualcommNext-Regular",
        fontWeight: " 500",
        fontSize: "16px",
        lineHeight: "24px",
        color: props?.palette?.login?.inputTitleColor,
        [muiTheme.breakpoints.down(1537)]: {
          fontSize: "10px",
          lineHeight: "15px",
        },
      },
    },
  }),
  checkBoxButtonSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiRadio-root": {
      padding: "0 4px 0 0",
      "&.Mui-checked": {
        color: props?.palette?.login?.loginButton,
      },
    },
    "& .MuiCheckbox-root": {
      padding: "0 4px 0 0",
      "&.Mui-checked": {
        color: props?.palette?.login?.loginButton,
      },
    },
    "& .MuiFormControlLabel-root": {
      "& .MuiTypography-root": {
        fontFamily: "QualcommNext-Regular",
        fontWeight: " 500",
        fontSize: "16px",
        lineHeight: "24px",
        color: props?.palette?.login?.inputTitleColor,
        [muiTheme.breakpoints.down(1537)]: {
          fontSize: "10px",
          lineHeight: "15px",
        },
      },
    },
  }),
  checkBoxButtonContainer: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 0.5,
  }),
  itemLabel: (props: any) => ({
    marginRight: "16px",
    fontFamily: `"QualcommNext-Regular"`,
    fontWeight: " 500",
    fontSize: "16px",
    lineHeight: "24px",
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: "10px",
      lineHeight: "15px",
      marginRight: "6px",
    },
  }),

  minValue: (props: any) => ({
    display: "flex",
    alignItems: "center",
    width: "300px",
    [muiTheme.breakpoints.down(1537)]: {
      width: " 235px",
    },
    [muiTheme.breakpoints.down(1025)]: {
      width: " 200px",
    },
  }),
  maxValue: (props: any) => ({
    display: "flex",
    alignItems: "center",
    width: "300px",
    [muiTheme.breakpoints.down(1537)]: {
      width: " 235px",
    },
    [muiTheme.breakpoints.down(1025)]: {
      width: " 200px",
    },
  }),
  minMaxValue: (props: any) => ({
    width: " 300px",
    alignItems: "center",
    display: "flex",
    [muiTheme.breakpoints.down(1537)]: {
      width: " 235px",
    },
    [muiTheme.breakpoints.down(1025)]: {
      width: " 200px",
    },
  }),
  contactsContainer: (props: any) => ({
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 114px)",
  }),
  contactsSection: (props: any) => ({
    flex: 1,
    overflow: "auto",
    padding: "20px 15px 20px 20px",
  }),
  availableContactsSection: (props: any) => ({
    flex: 1,
    overflow: "auto",
    padding: "20px 15px 20px 20px",
  }),
  contactsList: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: " space-between",
    paddingBottom: "20px",
  }),
  contactsCloseIcon: (props: any) => ({
    cursor: "pointer",
  }),

  listSection: (props: any) => ({
    border: "1px solid #59B974",
    backgroundColor: "#FCFFFC",
    borderRadius: "3px",
    height: "calc(100% - 65px)",
    overflow: "auto",
  }),
  listContactsSection: (props: any) => ({
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
  }),
  listName: (props: any) => ({
    flex: 1,
    paddingLeft: "20px",
    marginTop: "-5px",
  }),
  addedCheckbox: (props: any) => ({
    padding: "0 !important",
  }),
  drawerContainer: (props: any) => ({
    width: 400,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: "400px !important",
      boxSizing: "border-box",
      backgroundColor: "#FFFFFF",
      top: "84px",
      height: "calc(100vh - 113px)",
      [muiTheme.breakpoints.down(1335)]: {
        top: "50px",
        height: "calc(100vh - 79px)",
      },
    },
  }),
  noContacts: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  }),
  searchClass: (props: any) => ({
    border: `1px solid ${props?.palette?.dashboardList?.lightGrey}`,
    background: props?.palette?.dashboardList?.white,
    color: props?.palette?.dashboardList?.lightGrey3,
    borderRadius: 6,
    height: "40px",
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
    "& .MuiInputBase-root": {
      fontWeight: 500,
    },
  }),
  contactsName: (props: any) => ({
    paddingLeft: "5px",
  }),
  
  switchBtn: (props: any) => ({
   color: "#ffffff"
  }),
});
export default useStyles;
