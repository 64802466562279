const qcomAnalytics = {
  analytics: [
    {
      id: "overallAnalytics1",
      title: "Overall Analytics",
      category: "overallAnalytics",
      infoList: [
        {
          title: "Number of Packages",
          value: "500",
        },
        {
          title: "Energy Consumed",
          value: "25kWh",
        },

        {
          title: "Maintenance Hrs",
          value: "10Hrs",
        },
        {
          title: "Workers",
          value: "200",
        },
        {
          title: "Alerts",
          value: "50",
        },
      ],
      graphAnalytics: [
        {
          id: "overallAnalyticsgraph1",
          title: "No. of Pallets",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 543.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 345.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 541.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 542.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 344.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 349.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 544.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph2",
          title: "Items Rejected ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph3",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph4",
          title: "Alerts",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph5",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph6",
          title: "Number of Workers",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph7",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph8",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "overallAnalyticsgraph9",
          title: "Raw Material Level (T)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "forklift1",
      title: "Autonomous Forklift #1",
      category: "forklift",
      infoList: [
        {
          title: "Opr. Hours",
          value: "8Hrs",
        },
        {
          title: "Charge",
          value: "20%",
        },

        {
          title: "Maintenance Hrs",
          value: "12Hrs",
        },
        {
          title: "Trips Remaining",
          value: "125",
        },
        {
          title: "Alerts",
          value: "40",
        },
      ],
      graphAnalytics: [
        // {
        // id: "forkliftgraph1",
        // title: "Safety Infractions ",
        // analytics: {
        //   day: {
        //     analyticsData: [
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 27.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 26.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 44.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 27.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 26.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 54.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 31.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 42.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 44.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //     ],
        //   },
        //   weekly: {
        //     analyticsData: [
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 47.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 27.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 47.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 28.0,
        //       },
        //     ],
        //   },
        //   monthly: {
        //     analyticsData: [
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 44.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 34.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 26.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 54.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 26.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 34.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 47.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 54.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 34.0,
        //       },
        //     ],
        //   },
        //   yearly: {
        //     analyticsData: [
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 34.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 36.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 74.0,
        //       },
        //       {
        //         node: "2022-10-11T11:00:00",
        //         count: 54.0,
        //       },
        //       {
        //         node: "2022-10-11T12:00:00",
        //         count: 46.0,
        //       },
        //     ],
        // },
        // },
        // },
        {
          id: "forkliftgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph3",
          title: "Trips ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 467.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "forklift2",
      title: "Autonomous Forklift #2",
      category: "forklift",
      infoList: [
        {
          title: "Opr. Hours",
          value: "5Hrs",
        },
        {
          title: "Charge",
          value: "10%",
        },

        {
          title: "Maintenance Hrs",
          value: "10Hrs",
        },
        {
          title: "Trips Remaining",
          value: "225",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "forkliftgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 71.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 86.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 32.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "forkliftgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph3",
          title: "Trips ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 467.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "forklift3",
      title: "Autonomous Forklift #3",
      category: "forklift",
      infoList: [
        {
          title: "Opr. Hours",
          value: "8Hrs",
        },
        {
          title: "Charge",
          value: "10%",
        },

        {
          title: "Maintenance Hrs",
          value: "18Hrs",
        },
        {
          title: "Trips Remaining",
          value: "225",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "forkliftgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 28.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "forkliftgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph3",
          title: "Trips ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 467.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "forklift4",
      title: "Autonomous Forklift #4",
      category: "forklift",
      infoList: [
        {
          title: "Opr. Hours",
          value: "7Hrs",
        },
        {
          title: "Charge",
          value: "10%",
        },

        {
          title: "Maintenance Hrs",
          value: "10Hrs",
        },
        {
          title: "Trips Remaining",
          value: "225",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "forkliftgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 71.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 86.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 32.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "forkliftgraph4",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph3",
          title: "Trips ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 467.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "forklift5",
      title: "Autonomous Forklift #5",
      category: "forklift",
      infoList: [
        {
          title: "Opr. Hours",
          value: "13Hrs",
        },
        {
          title: "Charge",
          value: "20%",
        },

        {
          title: "Maintenance Hrs",
          value: "12Hrs",
        },
        {
          title: "Trips Remaining",
          value: "125",
        },
        {
          title: "Alerts",
          value: "40",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "forkliftgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 28.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "forkliftgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph3",
          title: "Trips ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 467.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "forkliftgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 217.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 247.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "aiCamera1",
      title: "AI Camera #23",
      category: "aiCamera",
      infoList: [
        {
          title: "Temperature",
          value: "70°C",
        },
        {
          title: "Humidity",
          value: "75%",
        },

        {
          title: "Predictive Maintenance Hrs",
          value: "12Hrs",
        },
        {
          title: "Equipment Efficiency",
          value: "80%",
        },
        {
          title: "Alerts",
          value: "50",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "aiCameragraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 21.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 25.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 92.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "aiCameragraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph3",
          title: "Rejects ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph8",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "aiCamera2",
      title: "AI Camera #24",
      category: "aiCamera",
      infoList: [
        {
          title: "Temperature",
          value: "65°C",
        },
        {
          title: "Humidity",
          value: "85%",
        },

        {
          title: "Predictive Maintenance Hrs",
          value: "16Hrs",
        },
        {
          title: "Equipment Efficiency",
          value: "75%",
        },
        {
          title: "Alerts",
          value: "70",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "aiCameragraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 21.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 21.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 25.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "aiCameragraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph3",
          title: "Rejects ",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
          },
        },
        {
          id: "aiCameragraph8",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "armPackager1",
      title: "Robotic Arm Packager #13",
      category: "armPackager",
      infoList: [
        {
          title: "Temperature",
          value: "68°C",
        },
        {
          title: "Humidity",
          value: "25%",
        },

        {
          title: "Days Until Maintenance",
          value: "15",
        },
        {
          title: "Equipment Efficiency",
          value: "60%",
        },
        {
          title: "Alerts",
          value: "80",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "armPackagergraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 68.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 92.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 91.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "aiCameragraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "armPackager2",
      title: "Robotic Arm Packager #14",
      category: "armPackager",
      infoList: [
        {
          title: "Temperature",
          value: "76°C",
        },
        {
          title: "Humidity",
          value: "75%",
        },

        {
          title: "Days Until Maintenance",
          value: "25",
        },
        {
          title: "Equipment Efficiency",
          value: "50%",
        },
        {
          title: "Alerts",
          value: "90",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "armPackagergraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 83.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 39.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 30.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 69.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 68.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 79.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "aiCameragraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "armPackager1",
      title: "Robotic Arm Packager #15",
      category: "armPackager",
      infoList: [
        {
          title: "Temperature",
          value: "78°C",
        },
        {
          title: "Humidity",
          value: "65%",
        },

        {
          title: "Days Until Maintenance",
          value: "65",
        },
        {
          title: "Equipment Efficiency",
          value: "65%",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "armPackagergraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 68.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 92.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 91.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "aiCameragraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
              ],
            },
          },
        },
        {
          id: "armPackagergraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "conveyorBelt1",
      title: "Conveyor Belt #02",
      category: "conveyorBelt",
      infoList: [
        {
          title: "Temperature",
          value: "68°C",
        },
        {
          title: "Belt Speed",
          value: "80RPM",
        },

        {
          title: "Tension",
          value: "4N/mm",
        },
        {
          title: "Vibration",
          value: "80mm",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "conveyorBeltgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 61.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 100.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 30.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 30.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 95.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 96.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 96.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 85.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 79.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 53.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 68.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 100.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 96.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "conveyorBeltgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "conveyorBelt2",
      title: "Conveyor Belt #03",
      category: "conveyorBelt",
      infoList: [
        {
          title: "Temperature",
          value: "58°C",
        },
        {
          title: "Belt Speed",
          value: "60RPM",
        },

        {
          title: "Tension",
          value: "7N/mm",
        },
        {
          title: "Vibration",
          value: "50mm",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "conveyorBeltgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 68.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 92.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 91.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "conveyorBeltgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
              ],
            },
          },
        },
        {
          id: "conveyorBeltgraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "pallet1",
      title: "Pallet #2",
      category: "pallet",
      infoList: [
        {
          title: "Pallets Completed",
          value: "200",
        },
        {
          title: "Days Until Maintenance",
          value: "15",
        },

        {
          title: "Equipment Efficiency",
          value: "65%",
        },
        {
          title: "Vibration",
          value: "80mm",
        },
        {
          title: "Alerts",
          value: "30",
        },
      ],
      graphAnalytics: [
        {
          id: "palletgraph1",
          title: "Pallets Completed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        // {
        //   id: "palletgraph2",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 71.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 71.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 61.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 71.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "palletgraph3",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "palletgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
              ],
            },
          },
        },
        {
          id: "palletgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
              ],
            },
          },
        },
        {
          id: "palletgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
              ],
            },
          },
        },
        {
          id: "palletgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },

                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "packager1",
      title: "Packager #1",
      category: "packager",
      infoList: [
        {
          title: "Packages Completed",
          value: "200",
        },
        {
          title: "Days Until Maintenance",
          value: "20",
        },

        {
          title: "Equipment Efficiency",
          value: "76%",
        },
        {
          title: "Vibration",
          value: "60mm",
        },
        {
          title: "Alerts",
          value: "70",
        },
      ],
      graphAnalytics: [
        {
          id: "packagergraph1",
          title: "Packages Completed",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 99,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 31,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 41,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 77,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 89,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 40,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 50,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 53,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 55,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 57,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 59,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 60,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 36,
                },
              ],
            },
          },
        },
        // {
        //   id: "packagergraph2",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T01:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T02:00:00",
        //           count: 32,
        //         },
        //         {
        //           node: "2022-10-11T03:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T04:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T05:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T06:00:00",
        //           count: 56,
        //         },
        //         {
        //           node: "2022-10-11T07:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-11T08:00:00",
        //           count: 84,
        //         },
        //         {
        //           node: "2022-10-11T09:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T10:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T13:00:00",
        //           count: 73,
        //         },
        //         {
        //           node: "2022-10-11T14:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-10-11T15:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T16:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T17:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T18:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-10-11T19:00:00",
        //           count: 39,
        //         },
        //         {
        //           node: "2022-10-11T20:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T21:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T22:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T23:00:00",
        //           count: 36,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35,
        //         },
        //         {
        //           node: "2022-10-12T12:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-13T12:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-14T12:00:00",
        //           count: 48,
        //         },
        //         {
        //           node: "2022-10-15T12:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-16T12:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-17T12:00:00",
        //           count: 36,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-02T00:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-03T00:00:00",
        //           count: 32,
        //         },
        //         {
        //           node: "2022-10-04T00:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-05T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-10-06T00:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-07T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-08T00:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-09T00:00:00",
        //           count: 48,
        //         },
        //         {
        //           node: "2022-10-10T00:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-12T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-13T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-14T00:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-10-15T00:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-16T00:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-17T00:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-18T00:00:00",
        //           count: 67,
        //         },
        //         {
        //           node: "2022-10-19T00:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-10-20T00:00:00",
        //           count: 89,
        //         },
        //         {
        //           node: "2022-10-21T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-22T00:00:00",
        //           count: 31,
        //         },
        //         {
        //           node: "2022-10-23T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-24T00:00:00",
        //           count: 53,
        //         },
        //         {
        //           node: "2022-10-25T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-10-26T00:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-10-27T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-28T00:00:00",
        //           count: 47,
        //         },
        //         {
        //           node: "2022-10-29T00:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-10-30T00:00:00",
        //           count: 39,
        //         },
        //         {
        //           node: "2022-10-31T00:00:00",
        //           count: 26,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-01-01T11:00:00",
        //           count: 40,
        //         },
        //         {
        //           node: "2022-02-01T12:00:00",
        //           count: 61,
        //         },
        //         {
        //           node: "2022-03-01T12:00:00",
        //           count: 32,
        //         },
        //         {
        //           node: "2022-04-01T12:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-05-01T12:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-06-01T12:00:00",
        //           count: 75,
        //         },
        //         {
        //           node: "2022-07-01T12:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-08-01T12:00:00",
        //           count: 87,
        //         },
        //         {
        //           node: "2022-09-01T12:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-10-01T12:00:00",
        //           count: 99,
        //         },
        //         {
        //           node: "2022-11-01T12:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-12-01T12:00:00",
        //           count: 31,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "packagergraph3",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 31,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 40,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 42,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 34,
                },
              ],
            },
          },
        },
        {
          id: "packagergraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 34,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 26,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 51,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 53,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 56,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 47,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 29,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 50,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 41,
                },
              ],
            },
          },
        },
        {
          id: "packagergraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 52,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 63,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 71,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 79,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 40,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 60,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 51,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 62,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 56,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 47,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 80,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 36,
                },
              ],
            },
          },
        },
        {
          id: "packagergraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 63,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 43,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 70,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 66,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 68,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 31,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 62,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 65,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 75,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 40,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 52,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 33,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 44,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 78,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 36,
                },
              ],
            },
          },
        },
        {
          id: "packagergraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 75,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 86,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 67,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 71,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 83,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 98,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 95,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 79,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 80,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 91,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 95,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 88,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 26,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-02-01T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-03-01T12:00:00",
                  count: 62,
                },
                {
                  node: "2022-04-01T12:00:00",
                  count: 83,
                },
                {
                  node: "2022-05-01T12:00:00",
                  count: 94,
                },
                {
                  node: "2022-06-01T12:00:00",
                  count: 35,
                },
                {
                  node: "2022-07-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-08-01T12:00:00",
                  count: 47,
                },
                {
                  node: "2022-09-01T12:00:00",
                  count: 78,
                },
                {
                  node: "2022-10-01T12:00:00",
                  count: 49,
                },
                {
                  node: "2022-11-01T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-12-01T12:00:00",
                  count: 71,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "amr1",
      title: "AMR #23",
      category: "amr",
      infoList: [
        {
          title: "Opr. Hours",
          value: "20Hrs",
        },
        {
          title: "Charge",
          value: "25%",
        },

        {
          title: "Maintenance Hrs",
          value: "10hrs",
        },
        {
          title: "Trips Remaining",
          value: "200",
        },
        {
          title: "Alerts",
          value: "50",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "amrgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T01:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T02:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T03:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T04:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-10-11T05:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T06:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T07:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-11T08:00:00",
        //           count: 38,
        //         },
        //         {
        //           node: "2022-10-11T09:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T10:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T13:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-10-11T14:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T15:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T16:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T17:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-11T18:00:00",
        //           count: 84,
        //         },
        //         {
        //           node: "2022-10-11T19:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T20:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T21:00:00",
        //           count: 51,
        //         },
        //         {
        //           node: "2022-10-11T22:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T23:00:00",
        //           count: 33,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-12T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-13T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-14T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-15T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-16T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-17T12:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-02T00:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-03T00:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-04T00:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-05T00:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-06T00:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-07T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-08T00:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-09T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-10T00:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-12T00:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-13T00:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-14T00:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-15T00:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-16T00:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-17T00:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-18T00:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-19T00:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-20T00:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-21T00:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-22T00:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-23T00:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-24T00:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-25T00:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-26T00:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-27T00:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-28T00:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-29T00:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-30T00:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-31T00:00:00",
        //           count: 60.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-01-01T00:00:00",
        //           count: 61,
        //         },
        //         {
        //           node: "2022-02-01T00:00:00",
        //           count: 62,
        //         },
        //         {
        //           node: "2022-03-01T00:00:00",
        //           count: 73,
        //         },
        //         {
        //           node: "2022-04-01T00:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-05-01T00:00:00",
        //           count: 75,
        //         },
        //         {
        //           node: "2022-06-01T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-07-01T00:00:00",
        //           count: 77,
        //         },
        //         {
        //           node: "2022-08-01T00:00:00",
        //           count: 78,
        //         },
        //         {
        //           node: "2022-09-01T00:00:00",
        //           count: 79,
        //         },
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 80,
        //         },
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 81,
        //         },
        //         {
        //           node: "2022-12-12T00:00:00",
        //           count: 82,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "amrgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 34.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 62,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph3",
          title: "Trips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 53,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 27,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 75,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 81,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 54,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 27,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 80,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 26,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 42,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 73,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 78,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 79,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 81,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 54,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "amr2",
      title: "AMR #24",
      category: "amr",
      infoList: [
        {
          title: "Opr. Hours",
          value: "20Hrs",
        },
        {
          title: "Charge",
          value: "35%",
        },

        {
          title: "Maintenance Hrs",
          value: "30Hrs",
        },
        {
          title: "Trips Remaining",
          value: "100",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "amrgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-11T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-11-11T01:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T02:00:00",
        //           count: 32,
        //         },
        //         {
        //           node: "2022-11-11T03:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T04:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T05:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-11-11T06:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-11-11T07:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-11-11T08:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-11-11T09:00:00",
        //           count: 76,
        //         },
        //         {
        //           node: "2022-11-11T10:00:00",
        //           count: 40,
        //         },
        //         {
        //           node: "2022-11-11T11:00:00",
        //           count: 48,
        //         },
        //         {
        //           node: "2022-11-11T12:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-11-11T13:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T14:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-11-11T15:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-11-11T16:00:00",
        //           count: 87,
        //         },
        //         {
        //           node: "2022-11-11T17:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-11-11T18:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-11-11T19:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T20:00:00",
        //           count: 87,
        //         },
        //         {
        //           node: "2022-11-11T21:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T22:00:00",
        //           count: 76,
        //         },
        //         {
        //           node: "2022-11-11T23:00:00",
        //           count: 56,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-12T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-13T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-11-14T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-11-15T12:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-11-16T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-11-17T12:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-02T00:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-11-03T00:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-11-04T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-05T00:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-11-06T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-07T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-08T00:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-11-09T00:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-11-10T00:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-11-11T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-12T00:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-11-13T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-14T00:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-11-15T00:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-11-16T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-17T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-11-18T00:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-11-19T00:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-11-20T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-21T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-22T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-11-23T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-24T00:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-11-25T00:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-11-26T00:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-11-27T00:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-11-28T00:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-11-29T00:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-11-30T00:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-11-31T00:00:00",
        //           count: 76.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-01-01T00:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-02-01T00:00:00",
        //           count: 56,
        //         },
        //         {
        //           node: "2022-03-01T00:00:00",
        //           count: 61,
        //         },
        //         {
        //           node: "2022-04-01T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-05-01T00:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-06-01T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-07-01T00:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-08-01T00:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-09-01T00:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 95,
        //         },
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-12-12T00:00:00",
        //           count: 53,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "amrgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 60.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph3",
          title: "Trips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 98,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 83,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 54,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 87,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 55,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 91,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 43,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 27,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 67,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 75,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 49,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 38,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 36,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 65.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 74,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 84,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 30,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 74,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 66,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 98,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 50,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 74,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 31,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 78,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 87,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 67,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 47,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 49,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 36,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 66.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 45.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 41,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 43,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 72,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 71,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 51,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 61,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 80,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 38,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 77,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 32,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 60,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 75,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 57,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 55.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 58.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 61,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 83,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "amr3",
      title: "AMR #25",
      category: "amr",
      infoList: [
        {
          title: "Opr. Hours",
          value: "24Hrs",
        },
        {
          title: "Charge",
          value: "65%",
        },
        {
          title: "Maintenance Hrs",
          value: "50Hrs",
        },
        {
          title: "Trips Remaining",
          value: "150",
        },
        {
          title: "Alerts",
          value: "70",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "amrgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T01:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T02:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T03:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-10-11T04:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-10-11T05:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T06:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T07:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-11T08:00:00",
        //           count: 38,
        //         },
        //         {
        //           node: "2022-10-11T09:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T10:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T13:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-10-11T14:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T15:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-10-11T16:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-10-11T17:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-10-11T18:00:00",
        //           count: 84,
        //         },
        //         {
        //           node: "2022-10-11T19:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-10-11T20:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T21:00:00",
        //           count: 51,
        //         },
        //         {
        //           node: "2022-10-11T22:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-10-11T23:00:00",
        //           count: 33,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-12T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-13T12:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-14T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-15T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-16T12:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-17T12:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-02T00:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-03T00:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-04T00:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-05T00:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-06T00:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-07T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-08T00:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-09T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-10T00:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T00:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-12T00:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-13T00:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-14T00:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-15T00:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-16T00:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-17T00:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-18T00:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-19T00:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-20T00:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-21T00:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-22T00:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-23T00:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-24T00:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-25T00:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-26T00:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-27T00:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-28T00:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-29T00:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-30T00:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-31T00:00:00",
        //           count: 60.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-01-01T00:00:00",
        //           count: 61,
        //         },
        //         {
        //           node: "2022-02-01T00:00:00",
        //           count: 62,
        //         },
        //         {
        //           node: "2022-03-01T00:00:00",
        //           count: 73,
        //         },
        //         {
        //           node: "2022-04-01T00:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-05-01T00:00:00",
        //           count: 75,
        //         },
        //         {
        //           node: "2022-06-01T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-07-01T00:00:00",
        //           count: 77,
        //         },
        //         {
        //           node: "2022-08-01T00:00:00",
        //           count: 78,
        //         },
        //         {
        //           node: "2022-09-01T00:00:00",
        //           count: 79,
        //         },
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 80,
        //         },
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 81,
        //         },
        //         {
        //           node: "2022-12-12T00:00:00",
        //           count: 82,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "amrgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 34.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 62,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph3",
          title: "Trips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 53,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 27,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 75,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 81,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 54,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 27,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 80,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 53,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 26,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 57,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 59,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 74,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 84,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 31,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 42,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-10-11T01:00:00",
                  count: 31,
                },
                {
                  node: "2022-10-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-10-11T03:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T05:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T07:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-11T09:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T13:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-11T15:00:00",
                  count: 35,
                },
                {
                  node: "2022-10-11T16:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T18:00:00",
                  count: 48,
                },
                {
                  node: "2022-10-11T19:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-11T20:00:00",
                  count: 50,
                },
                {
                  node: "2022-10-11T21:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-12T12:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-13T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-14T12:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-15T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-16T12:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T12:00:00",
                  count: 37,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-01T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-02T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-03T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-04T00:00:00",
                  count: 33,
                },
                {
                  node: "2022-10-05T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-10-06T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-07T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-10-08T00:00:00",
                  count: 37,
                },
                {
                  node: "2022-10-09T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-10-10T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T00:00:00",
                  count: 40,
                },
                {
                  node: "2022-10-12T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-13T00:00:00",
                  count: 42,
                },
                {
                  node: "2022-10-14T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-15T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-16T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-10-17T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-18T00:00:00",
                  count: 47,
                },
                {
                  node: "2022-10-19T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-10-20T00:00:00",
                  count: 49,
                },
                {
                  node: "2022-10-21T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-22T00:00:00",
                  count: 51,
                },
                {
                  node: "2022-10-23T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-10-24T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-25T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-26T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-10-27T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-10-28T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-10-29T00:00:00",
                  count: 58,
                },
                {
                  node: "2022-10-30T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-10-31T00:00:00",
                  count: 60,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 72,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 73,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 78,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 79,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 60,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 81,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 54,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "amr4",
      title: "AMR #26",
      category: "amr",
      infoList: [
        {
          title: "Opr. Hours",
          value: "17Hrs",
        },
        {
          title: "Charge",
          value: "45%",
        },
        {
          title: "Maintenance Hrs",
          value: "05Hrs",
        },
        {
          title: "Trips Remaining",
          value: "100",
        },
        {
          title: "Alerts",
          value: "80",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "amrgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-11T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-11-11T01:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T02:00:00",
        //           count: 32,
        //         },
        //         {
        //           node: "2022-11-11T03:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T04:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T05:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-11-11T06:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-11-11T07:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-11-11T08:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-11-11T09:00:00",
        //           count: 76,
        //         },
        //         {
        //           node: "2022-11-11T10:00:00",
        //           count: 40,
        //         },
        //         {
        //           node: "2022-11-11T11:00:00",
        //           count: 48,
        //         },
        //         {
        //           node: "2022-11-11T12:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-11-11T13:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T14:00:00",
        //           count: 54,
        //         },
        //         {
        //           node: "2022-11-11T15:00:00",
        //           count: 64,
        //         },
        //         {
        //           node: "2022-11-11T16:00:00",
        //           count: 87,
        //         },
        //         {
        //           node: "2022-11-11T17:00:00",
        //           count: 27,
        //         },
        //         {
        //           node: "2022-11-11T18:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-11-11T19:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-11-11T20:00:00",
        //           count: 87,
        //         },
        //         {
        //           node: "2022-11-11T21:00:00",
        //           count: 36,
        //         },
        //         {
        //           node: "2022-11-11T22:00:00",
        //           count: 76,
        //         },
        //         {
        //           node: "2022-11-11T23:00:00",
        //           count: 56,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-12T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-13T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-11-14T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-11-15T12:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-11-16T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-11-17T12:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-02T00:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-11-03T00:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-11-04T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-05T00:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-11-06T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-07T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-08T00:00:00",
        //           count: 91.0,
        //         },
        //         {
        //           node: "2022-11-09T00:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-11-10T00:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-11-11T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-12T00:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-11-13T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-14T00:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-11-15T00:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-11-16T00:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-11-17T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-11-18T00:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-11-19T00:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-11-20T00:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-11-21T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-22T00:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-11-23T00:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-11-24T00:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-11-25T00:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-11-26T00:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-11-27T00:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-11-28T00:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-11-29T00:00:00",
        //           count: 28.0,
        //         },
        //         {
        //           node: "2022-11-30T00:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-11-31T00:00:00",
        //           count: 76.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-01-01T00:00:00",
        //           count: 43,
        //         },
        //         {
        //           node: "2022-02-01T00:00:00",
        //           count: 56,
        //         },
        //         {
        //           node: "2022-03-01T00:00:00",
        //           count: 61,
        //         },
        //         {
        //           node: "2022-04-01T00:00:00",
        //           count: 46,
        //         },
        //         {
        //           node: "2022-05-01T00:00:00",
        //           count: 29,
        //         },
        //         {
        //           node: "2022-06-01T00:00:00",
        //           count: 26,
        //         },
        //         {
        //           node: "2022-07-01T00:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-08-01T00:00:00",
        //           count: 28,
        //         },
        //         {
        //           node: "2022-09-01T00:00:00",
        //           count: 65,
        //         },
        //         {
        //           node: "2022-10-01T00:00:00",
        //           count: 95,
        //         },
        //         {
        //           node: "2022-11-01T00:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-12-12T00:00:00",
        //           count: 53,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "amrgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 60.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 75.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 39,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 61,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph3",
          title: "Trips",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 98,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 83,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 54,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 87,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 55,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 91,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 43,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 27,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 67,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 75,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 49,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 38,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 36,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 27.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph4",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 65.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 28,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph5",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 32,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 74,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 54,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 84,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 30,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 74,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 84,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 29,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 44,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 64,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 67.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 74.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 26.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 66,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 98,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 50,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 29,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 38,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 74,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph6",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 34,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 37,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 64,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 31,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 28,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 78,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 76,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 46,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 87,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 67,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 45,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 47,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 49,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 36,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 66.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 45.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 67,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 52,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 46,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 41,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 71,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 54,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 45,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 87,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 43,
                },
              ],
            },
          },
        },
        {
          id: "amrgraph7",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-11-11T00:00:00",
                  count: 30,
                },
                {
                  node: "2022-11-11T01:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T02:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T03:00:00",
                  count: 39,
                },
                {
                  node: "2022-11-11T04:00:00",
                  count: 72,
                },
                {
                  node: "2022-11-11T05:00:00",
                  count: 71,
                },
                {
                  node: "2022-11-11T06:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T07:00:00",
                  count: 51,
                },
                {
                  node: "2022-11-11T08:00:00",
                  count: 61,
                },
                {
                  node: "2022-11-11T09:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T10:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T11:00:00",
                  count: 80,
                },
                {
                  node: "2022-11-11T12:00:00",
                  count: 38,
                },
                {
                  node: "2022-11-11T13:00:00",
                  count: 63,
                },
                {
                  node: "2022-11-11T14:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T15:00:00",
                  count: 77,
                },
                {
                  node: "2022-11-11T16:00:00",
                  count: 26,
                },
                {
                  node: "2022-11-11T17:00:00",
                  count: 32,
                },
                {
                  node: "2022-11-11T18:00:00",
                  count: 60,
                },
                {
                  node: "2022-11-11T19:00:00",
                  count: 36,
                },
                {
                  node: "2022-11-11T20:00:00",
                  count: 65,
                },
                {
                  node: "2022-11-11T21:00:00",
                  count: 33,
                },
                {
                  node: "2022-11-11T22:00:00",
                  count: 75,
                },
                {
                  node: "2022-11-11T23:00:00",
                  count: 57,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-11-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-11-12T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-13T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-11-14T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-11-15T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-16T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-11-17T12:00:00",
                  count: 55.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-11-01T00:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-11-02T00:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-11-03T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-04T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-05T00:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-11-06T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-07T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-08T00:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-11-09T00:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-11-10T00:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-11-11T00:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-11-12T00:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-11-13T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-14T00:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-11-15T00:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-11-16T00:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-11-17T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-18T00:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-11-19T00:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-11-20T00:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-11-21T00:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-11-22T00:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-11-23T00:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-11-24T00:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-11-25T00:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-11-26T00:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-11-27T00:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-11-28T00:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-11-29T00:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-11-30T00:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-11-31T00:00:00",
                  count: 58.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-01-01T00:00:00",
                  count: 61,
                },
                {
                  node: "2022-02-01T00:00:00",
                  count: 56,
                },
                {
                  node: "2022-03-01T00:00:00",
                  count: 36,
                },
                {
                  node: "2022-04-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-05-01T00:00:00",
                  count: 83,
                },
                {
                  node: "2022-06-01T00:00:00",
                  count: 76,
                },
                {
                  node: "2022-07-01T00:00:00",
                  count: 27,
                },
                {
                  node: "2022-08-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-09-01T00:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-01T00:00:00",
                  count: 55,
                },
                {
                  node: "2022-11-01T00:00:00",
                  count: 43,
                },
                {
                  node: "2022-12-12T00:00:00",
                  count: 26,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipment1",
      title: "Equipment #1",
      category: "equipment",
      infoList: [
        {
          title: "Temperature",
          value: "58°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },

        {
          title: "Pressure",
          value: "6hPa",
        },
        {
          title: "Equipment Efficiency",
          value: "80%",
        },
        {
          title: "Alerts",
          value: "60",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "equipmentgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 21.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 62.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 27.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 24.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 57.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 33.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 82.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 57.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 37.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 54.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 21.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 25.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 41.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 97.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 76.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 87.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 31.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 66.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 89.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "equipmentgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 24.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
              ],
            },
          },
        },

        {
          id: "equipmentgraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 93.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
              ],
            },
          },
        },
        {
          id: "equipmentgraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
              ],
            },
          },
        },
        {
          id: "equipmentgraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 93.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 68.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 54.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 41.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "equipmentgraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 62.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 37.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 27.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
              ],
            },
          },
        },
        {
          id: "equipmentgraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 57.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 96.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 83.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 87.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 91.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 85.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 28.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 57.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 31.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 97.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 82.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 76.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipment2",
      title: "Equipment #2",
      category: "equipment",
      infoList: [
        {
          title: "Temperature",
          value: "68°C",
        },
        {
          title: "Humidity",
          value: "80%",
        },

        {
          title: "Pressure",
          value: "4hPa",
        },
        {
          title: "Equipment Efficiency",
          value: "60%",
        },
        {
          title: "Alerts",
          value: "80",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "equipmentgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 100.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 92.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 64.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 72.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 95.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 32.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 100.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 69.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 86.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 25.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 50.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 47.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 30.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 67.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 53.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 88.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 95.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 42.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 77.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 89.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 51.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 55.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 69.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 100.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 82.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "Equipmentgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 71.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 61.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 51.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 92.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 72.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 32.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 86.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 25.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 50.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 47.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 67.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 53.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 88.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 95.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 42.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 77.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 89.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 21.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 55.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 100.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 82.0,
                },
              ],
            },
          },
        },
      ],
    },
    {
      id: "equipment3",
      title: "Equipment #3",
      category: "equipment",
      infoList: [
        {
          title: "Temperature",
          value: "48°C",
        },
        {
          title: "Humidity",
          value: "60%",
        },

        {
          title: "Pressure",
          value: "8hPa",
        },
        {
          title: "Equipment Efficiency",
          value: "75%",
        },
        {
          title: "Alerts",
          value: "50",
        },
      ],
      graphAnalytics: [
        // {
        //   id: "equipmentgraph1",
        //   title: "Safety Infractions ",
        //   analytics: {
        //     day: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 63.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 70.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 26.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 94.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 73.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 50.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 43.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 35.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 39.0,
        //         },
        //       ],
        //     },
        //     weekly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 69.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 74,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 59.0,
        //         },
        //       ],
        //     },
        //     monthly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 30.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 33.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 79.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 52.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 84.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 65.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 98.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 29.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 81.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 45.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 40.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 59.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 58.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 83.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 78.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 36.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 80.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //       ],
        //     },
        //     yearly: {
        //       analyticsData: [
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 44,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 49.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 38.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 56.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 60.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 39.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 46.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 99.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 48.0,
        //         },
        //         {
        //           node: "2022-10-11T11:00:00",
        //           count: 34.0,
        //         },
        //         {
        //           node: "2022-10-11T12:00:00",
        //           count: 90.0,
        //         },
        //       ],
        //     },
        //   },
        // },
        {
          id: "Equipmentgraph2",
          title: "Hours of Operation (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 66.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph3",
          title: "Predictive Maintenance Hours (Hrs)",
          analytics: {
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph4",
          title: "Equipment Efficiency (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph5",
          title: "Temperature (°C)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph6",
          title: "Energy Consumed (kWh)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 64.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
        {
          id: "Equipmentgraph7",
          title: "Humidity (%)",
          analytics: {
            day: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 63.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 70.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 26.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 94.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 73.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 50.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 43.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 35.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 39.0,
                },
              ],
            },
            weekly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 69.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 74,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
              ],
            },
            monthly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 30.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 33.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 79.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 52.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 84.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 65.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 98.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 29.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 81.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 45.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 40.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 44.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 59.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 58.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 83.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 78.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 36.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 80.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
              ],
            },
            yearly: {
              analyticsData: [
                {
                  node: "2022-10-11T11:00:00",
                  count: 44,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 49.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 38.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 56.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 60.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 39.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 46.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 99.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 48.0,
                },
                {
                  node: "2022-10-11T11:00:00",
                  count: 34.0,
                },
                {
                  node: "2022-10-11T12:00:00",
                  count: 90.0,
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export default qcomAnalytics;
