const equipmentViewDetails = [
  {
    id: 1,
    category: "forklift",
    title: "Autonomous ForkLift#1",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Humidity",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Battery Level",
        value: "70%",
      },
      {
        title: "Trips Remaining",
        value: "05",
      },
    ],
    alerts: {
      events: [
        {
          title: "In Charging",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "Charging Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Low Battery Level",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
        {
          title: "Low Equipment Efficiency",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#1",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 11,
    category: "forklift",
    title: "Autonomous ForkLift#2",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "48°C",
      },
      {
        title: "Humidity",
        value: "70%",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Power Usage",
        value: "65KW",
      },
      {
        title: "Vibration",
        value: "12mm",
      },
      {
        title: "Pressure",
        value: "10hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Battery Level",
        value: "75%",
      },
      {
        title: "Trips Remaining",
        value: "30",
      },
    ],
    alerts: {
      events: [
        {
          title: "In Charging",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "Charging Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Low Battery Level",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
        {
          title: "Low Equipment Efficiency",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#2",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 12,
    category: "forklift",
    title: "Autonomous ForkLift#3",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "38°C",
      },
      {
        title: "Humidity",
        value: "65%",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Power Usage",
        value: "56KW",
      },
      {
        title: "Vibration",
        value: "14mm",
      },
      {
        title: "Pressure",
        value: "12hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Battery Level",
        value: "55%",
      },
      {
        title: "Trips Remaining",
        value: "40",
      },
    ],
    alerts: {
      events: [
        {
          title: "In Charging",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "Charging Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Low Battery Level",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
        {
          title: "Low Equipment Efficiency",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#3",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 13,
    category: "forklift",
    title: "Autonomous ForkLift#4",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "58°C",
      },
      {
        title: "Humidity",
        value: "63%",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Power Usage",
        value: "64KW",
      },
      {
        title: "Vibration",
        value: "12mm",
      },
      {
        title: "Pressure",
        value: "15hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Battery Level",
        value: "62%",
      },
      {
        title: "Trips Remaining",
        value: "48",
      },
    ],
    alerts: {
      events: [
        {
          title: "In Charging",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "Charging Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Low Battery Level",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
        {
          title: "Low Equipment Efficiency",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#4",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 14,
    category: "forklift",
    title: "Autonomous ForkLift#5",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "43°C",
      },
      {
        title: "Humidity",
        value: "90%",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Power Usage",
        value: "70KW",
      },
      {
        title: "Vibration",
        value: "08mm",
      },
      {
        title: "Pressure",
        value: "12hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Battery Level",
        value: "52%",
      },
      {
        title: "Trips Remaining",
        value: "63",
      },
    ],
    alerts: {
      events: [
        {
          title: "In Charging",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "Charging Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Low Battery Level",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
        {
          title: "Low Equipment Efficiency",
          area: "Distribution Area, Zone 1",
          eqipment: "Autonomous ForkLift#5",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 2,
    category: "aiCamera",
    title: "AI Camera #23",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Humidity",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
      {
        title: "Rejects",
        value: "05",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Camera Not Operational",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
        {
          title: "Camera Offline",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
        {
          title: "Camera Occlusion",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
        {
          title: "Low Battery Level",
          area: "Packaging Area, Zone 1",
          eqipment: "AI Camera #23",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Alerts",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 21,
    category: "aiCamera",
    title: "AI Camera #24",
    area: "Inspection Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "48°C",
      },
      {
        title: "Humidity",
        value: "75%",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Power Usage",
        value: "75KW",
      },
      {
        title: "Vibration",
        value: "12mm",
      },
      {
        title: "Pressure",
        value: "15hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "80%",
      },
      {
        title: "Rejects",
        value: "07",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
        {
          title: "Camera Not Operational",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
        {
          title: "Camera Offline",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
        {
          title: "Camera Occlusion",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
        {
          title: "Low Battery Level",
          area: "Inspection Area, Zone 2",
          eqipment: "AI Camera #24",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "temparature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Alerts",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 3,
    category: "robotArm",
    title: "Robotic Arm Packager #13",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Humidity",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
      {
        title: "Days Maintanence",
        value: "05",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Not Operational",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "High Vibration",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "High Pressure",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "High Maintenance Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #13",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Days Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 48.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 35.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 31,
    category: "robotArm",
    title: "Robotic Arm Packager #14",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "50°C",
      },
      {
        title: "Humidity",
        value: "70%",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Power Usage",
        value: "70KW",
      },
      {
        title: "Vibration",
        value: "12mm",
      },
      {
        title: "Pressure",
        value: "11hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "60%",
      },
      {
        title: "Days Maintanence",
        value: "06",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Not Operational",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "High Vibration",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "High Pressure",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "High Maintenance Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Packaging Area, Zone 1",
          eqipment: "Robotic Arm Packager #14",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Days Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 43.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 54.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 35.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 46.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 36.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 58.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 36.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 32,
    category: "robotArm",
    title: "Robotic Arm Packager #15",
    area: "Palleting Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "65°C",
      },
      {
        title: "Humidity",
        value: "85%",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Power Usage",
        value: "72KW",
      },
      {
        title: "Vibration",
        value: "11mm",
      },
      {
        title: "Pressure",
        value: "12hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "65%",
      },
      {
        title: "Days Maintanence",
        value: "05",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Not Operational",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "High Vibration",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "High Pressure",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "High Maintenance Hours",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Palleting Area, Zone 1",
          eqipment: "Robotic Arm Packager #15",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Days Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 48.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 35.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 4,
    category: "conveyerBelt",
    title: "Conveyor Belt#02",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Belt Speed",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
      {
        title: "Tension",
        value: "04",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance completed",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Belt Speed",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "High Vibration",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Material Stuck On Conveyor Belt",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "Belt Slippage",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
        {
          title: "Seized Rollers and Blockages",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#02",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Predictive Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 43.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 54.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 72.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 86.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 39.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 45.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 80.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 92.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 54.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 94.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 52.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 80.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 92.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 62.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 42.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 83.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 54.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 78.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 80.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 59.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 54.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 94.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 41,
    category: "conveyerBelt",
    title: "Conveyor Belt#03",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "58°C",
      },
      {
        title: "Belt Speed",
        value: "60%",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Power Usage",
        value: "70KW",
      },
      {
        title: "Vibration",
        value: "50mm",
      },
      {
        title: "Pressure",
        value: "16hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "65%",
      },
      {
        title: "Tension",
        value: "07",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance completed",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Belt Speed",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "High Vibration",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "High Power Usage",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Material Stuck On Conveyor Belt",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "Belt Slippage",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
        {
          title: "Seized Rollers and Blockages",
          area: "Production Area, Zone 2",
          eqipment: "Conveyor Belt#03",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Predictive Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 33.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 39.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 46.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 43.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 48.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 42.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 35.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 26.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 26.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 26.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 26.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 26.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 25.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 42.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 30.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 39.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 43.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 41.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 5,
    category: "pallet",
    title: "Pallet",
    area: "Palleting Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Pallete Completed",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "80mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
      {
        title: "Days Maintanence",
        value: "04",
      },
    ],
    alerts: {
      events: [
        {
          title: "Palletizing Completed",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
        {
          title: "Palletizing Inprogress",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Completed",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Vibration",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "High Maintenance Hours",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Palleting Area, Zone 1",
          eqipment: "Pallet#2",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Pallets Completed",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Days Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 48.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 35.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 6,
    category: "packager",
    title: "Packager05",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Packages Completed",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "80mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
      {
        title: "Days Maintanence",
        value: "04",
      },
    ],
    alerts: {
      events: [
        {
          title: "Packaging Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
        {
          title: "Packaging Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
        {
          title: "Packaging Maintenance Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
        {
          title: "Packaging Maintenance Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Vibration",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "High Maintenance Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Packaging Area, Zone 1",
          eqipment: "packager",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Packages Completed",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Days Maintanence",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 32.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 48.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 35.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 7,
    category: "amr",
    title: "AMR#23",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Packages Completed",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Charge",
        value: "70%",
      },
      {
        title: "Trips Remaining",
        value: "05",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Pressure - 70Pa",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
        {
          title: "Low Battery Level - 20%",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#23",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 71,
    category: "amr",
    title: "AMR#24",
    area: "Distribution Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "55°C",
      },
      {
        title: "Packages Completed",
        value: "70%",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Power Usage",
        value: "70KW",
      },
      {
        title: "Vibration",
        value: "15mm",
      },
      {
        title: "Pressure",
        value: "14hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Charge",
        value: "60%",
      },
      {
        title: "Trips Remaining",
        value: "40",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Pressure - 70hPa",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
        {
          title: "Low Battery Level - 20%",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Distribution Area, Zone 1",
          eqipment: "AMR#24",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 72,
    category: "amr",
    title: "AMR#25",
    area: "Inspection Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "65°C",
      },
      {
        title: "Packages Completed",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "10mm",
      },
      {
        title: "Pressure",
        value: "13hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Charge",
        value: "50%",
      },
      {
        value: "50",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Pressure - 70hPa",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
        {
          title: "Low Battery Level - 20%",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Inspection Area, Zone 1",
          eqipment: "AMR#25",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 73,
    category: "amr",
    title: "AMR#26",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Temperature",
        value: "62°C",
      },
      {
        title: "Packages Completed",
        value: "70%",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Power Usage",
        value: "70KW",
      },
      {
        title: "Vibration",
        value: "15mm",
      },
      {
        title: "Pressure",
        value: "14hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Charge",
        value: "40%",
      },
      {
        title: "Trips Remaining",
        value: "58",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
        {
          title: "Maintenance InProgress",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Pressure - 70hPa",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
        {
          title: "Low Battery Level - 20%",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Maintenance Due",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
        {
          title: "Low Operating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "AMR#26",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Trips",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 8,
    category: "equipment",
    title: "Equipment#1",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "58°C",
      },
      {
        title: "Humidity",
        value: "60%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "60mm",
      },
      {
        title: "Pressure",
        value: "06hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "70%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Vibration",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
        {
          title: "High Pressure - 70hPa",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#1",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 77,
    category: "equipment",
    title: "Equipment#2",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "68°C",
      },
      {
        title: "Humidity",
        value: "80%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "80mm",
      },
      {
        title: "Pressure",
        value: "04hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "60%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Vibration",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
        {
          title: "High Pressure - 70hPa",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#2",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 88.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 53.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 70.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 81,
    category: "equipment",
    title: "Equipment#3",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Temperature",
        value: "48°C",
      },
      {
        title: "Humidity",
        value: "60%",
      },
      {
        title: "Opr.Hours",
        value: "4Hrs",
      },
      {
        title: "Power Usage",
        value: "80KW",
      },
      {
        title: "Vibration",
        value: "50mm",
      },
      {
        title: "Pressure",
        value: "08hPa",
      },
      {
        title: "Predictive Maintenance",
        value: "02-22-2023",
      },
      {
        title: "Equipment Efficiency",
        value: "75%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Maintenance Completed",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
        {
          title: "Maintenance Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "High Vibration",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
        {
          title: "High Temperature",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
        {
          title: "High Pressure - 70hPa",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Operating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
        {
          title: "Maintenance Due",
          area: "Production Area, Zone 2",
          eqipment: "Equipment#3",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Temperature",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 50.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Humidity",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Hours of Operation",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Equipment Efficiency",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 9,
    category: "workers",
    title: "Worker#1",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Worker Name",
        value: "Logan",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 1",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "80%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "80%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#1",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 91,
    category: "workers",
    title: "Worker#2",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Worker Name",
        value: "Jessica",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 1",
      },
      {
        title: "Opr.Hours",
        value: "14Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 2",
      },
      {
        title: "Safety Score",
        value: "65%",
      },
      {
        title: "Safety Infraction",
        value: "55",
      },
      {
        title: "Productivity Score",
        value: "60%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#2",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 92,
    category: "workers",
    title: "Worker#3",
    area: "Inspection Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "James William",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "20Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "75%",
      },
      {
        title: "Safety Infraction",
        value: "78",
      },
      {
        title: "Productivity Score",
        value: "55%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#3",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 93,
    category: "workers",
    title: "Worker#4",
    area: "Inspection Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "John William",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "75%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "80%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#4",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 94,
    category: "workers",
    title: "Worker#5",
    area: "Inspection Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "William Alexander",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "68%",
      },
      {
        title: "Safety Infraction",
        value: "55",
      },
      {
        title: "Productivity Score",
        value: "60%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#5",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 95,
    category: "workers",
    title: "Worker#6",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Michael John",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 2",
      },
      {
        title: "Safety Score",
        value: "78%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "80%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#6",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 96,
    category: "workers",
    title: "Worker#7",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Christopher Nolan",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 2",
      },
      {
        title: "Safety Score",
        value: "76%",
      },
      {
        title: "Safety Infraction",
        value: "43",
      },
      {
        title: "Productivity Score",
        value: "65%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#7",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 97,
    category: "workers",
    title: "Worker#8",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Jack Sparrow",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "79%",
      },
      {
        title: "Safety Infraction",
        value: "58",
      },
      {
        title: "Productivity Score",
        value: "62%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#8",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 98,
    category: "workers",
    title: "Worker#9",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Johnny Depp",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "70%",
      },
      {
        title: "Safety Infraction",
        value: "40",
      },
      {
        title: "Productivity Score",
        value: "80%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#9",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 99,
    category: "workers",
    title: "Worker#10",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Michael",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "7Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "68%",
      },
      {
        title: "Safety Infraction",
        value: "38",
      },
      {
        title: "Productivity Score",
        value: "60%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#10",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 100,
    category: "workers",
    title: "Worker#11",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Jacob",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "60%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "80%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#11",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 101,
    category: "workers",
    title: "Worker#12",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Ethan",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "60%",
      },
      {
        title: "Safety Infraction",
        value: "60",
      },
      {
        title: "Productivity Score",
        value: "90%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#12",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 102,
    category: "workers",
    title: "Worker#13",
    area: "Production Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Henry William",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 2",
      },
      {
        title: "Safety Score",
        value: "40%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "89%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Production Area, Zone 2",
          eqipment: "Worker#13",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 103,
    category: "workers",
    title: "Worker#14",
    area: "Packaging Area, Zone 1",
    infoData: [
      {
        title: "Worker Name",
        value: "Samuel",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 1",
      },
      {
        title: "Opr.Hours",
        value: "6Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "80%",
      },
      {
        title: "Safety Infraction",
        value: "55",
      },
      {
        title: "Productivity Score",
        value: "70%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Packaging Area, Zone 1",
          eqipment: "Worker#14",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 104,
    category: "workers",
    title: "Worker#15",
    area: "Palleting Area, Zone 1",
    infoData: [
      {
        title: "Worker Name",
        value: "William Benjamin",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 1",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "65%",
      },
      {
        title: "Safety Infraction",
        value: "68",
      },
      {
        title: "Productivity Score",
        value: "82%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Palleting Area, Zone 1",
          eqipment: "Worker#15",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 56.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 29.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 68.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 60.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 7.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 28.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
  {
    id: 105,
    category: "workers",
    title: "Worker#16",
    area: "Inspection Area, Zone 2",
    infoData: [
      {
        title: "Worker Name",
        value: "Nolan",
      },
      {
        title: "Role",
        value: "Worker",
      },
      {
        title: "Job Location",
        value: "Zone 2",
      },
      {
        title: "Opr.Hours",
        value: "8Hrs",
      },
      {
        title: "Job Shift",
        value: "Shift 1",
      },
      {
        title: "Safety Score",
        value: "60%",
      },
      {
        title: "Safety Infraction",
        value: "48",
      },
      {
        title: "Productivity Score",
        value: "50%",
      },
    ],
    alerts: {
      events: [
        {
          title: "Work completed",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
        {
          title: "Work Inprogress",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
      ],
      incidents: [
        {
          title: "Safety Infraction",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
        {
          title: "Conveyor Belt Proximity",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
        {
          title: "Low Safety Score",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
      ],
      oprAlerts: [
        {
          title: "Low Oerating Hours",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
        {
          title: "Low Productivity Score ",
          area: "Inspection Area, Zone 2",
          eqipment: "Worker#16",
          timeStamp: "",
        },
      ],
    },
    analytics: [
      {
        type: "Operating Hours",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 65.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 76.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 33.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 75.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 79.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 38.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 40.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 84.0,
              },
            ],
          },
        },
      },
      {
        type: "Productivity Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 51.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 67.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 31.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 91.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 61.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 74.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 44.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 81.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 37.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 64.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 71.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 87.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 97.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 34.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 57.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 77.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Infraction",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 27.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
            ],
          },
        },
      },
      {
        type: "Safety Score",
        data: {
          day: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 12.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
          weekly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
            ],
          },
          monthly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 16.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 13.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 10.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 11.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 17.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 47.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 18.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 19.0,
              },
            ],
          },
          yearly: {
            analyticsData: [
              {
                node: "2022-10-11T11:00:00",
                count: 20.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 14.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 23.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 22.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 15.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 21.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 22.0,
              },
              {
                count: 14.0,
              },
              {
                node: "2022-10-11T11:00:00",
                count: 19.0,
              },
              {
                node: "2022-10-11T12:00:00",
                count: 24.0,
              },
            ],
          },
        },
      },
    ],
  },
];
export default equipmentViewDetails;
