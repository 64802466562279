import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import theme from "../../theme/theme";
import useStyles from "./styles";
import {
  NumberOfPackagesIcon,
  EnergyConsumedIcon,
  MaintenanceHrsIcon,
  WorkersIcon,
  SafetyInfractionsIcon,
  OprHoursIcon,
  TripsRemainingForkliftIcon,
  BeltSpeedIcon,
  ChargeIcon,
  EquipmentIcon,
  HumidityIcon,
  PalletsCompletedIcon,
  PressureIcon,
  TemperatureIcon,
  TensionIcon,
  VibrationIcon,
} from "../../assets/analyticsInfoIcons";
import InfoIcon from "elements/InfoIcon";
import Select from "elements/Select";
import Chart from "elements/Chart";
import moment from "moment";
import useWindowDimensions from "hooks/useWindowDimensions";
import RealTimeChart from "elements/RealTimeChart";

const ConfigurationContainer: React.FC<any> = (props) => {
  const { analyticsCategoryLists, selectedAnalyticListItem } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const {
    analyticsGridSection,
    flex1,
    analyticsGrid,
    graphSection,
    infoIconContainer,
    incomeCurrentSection,
    incomeText,
    customSelect,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const InfoIconList = [
    {
      color: "#73E893",
      title: analyticsCategoryLists[0]?.value,
      tagLine: analyticsCategoryLists[0]?.title,
      icon:
        analyticsCategoryLists[0]?.title === "Opr. Hours"
          ? OprHoursIcon
          : analyticsCategoryLists[0]?.title === "Number of Packages"
          ? NumberOfPackagesIcon
          : analyticsCategoryLists[0]?.title === "Pallets Completed" ||
            analyticsCategoryLists[0]?.title === "Packages Completed"
          ? PalletsCompletedIcon
          : TemperatureIcon,
    },
    {
      color: "#EFBD81",
      title: analyticsCategoryLists[1]?.value,
      tagLine: analyticsCategoryLists[1]?.title,
      icon:
        analyticsCategoryLists[1]?.title === "Charge"
          ? ChargeIcon
          : analyticsCategoryLists[1]?.title === "Energy Consumed"
          ? EnergyConsumedIcon
          : analyticsCategoryLists[1]?.title === "Belt Speed"
          ? BeltSpeedIcon
          : analyticsCategoryLists[1]?.title === "Days Until Maintenance"
          ? MaintenanceHrsIcon
          : HumidityIcon,
    },
    {
      color: "#68B5C7",
      title: analyticsCategoryLists[2]?.value,
      tagLine: analyticsCategoryLists[2]?.title,
      icon:
        analyticsCategoryLists[2]?.title === "Tension"
          ? TensionIcon
          : analyticsCategoryLists[2]?.title === "Equipment Efficiency"
          ? EquipmentIcon
          : analyticsCategoryLists[2]?.title === "Pressure"
          ? PressureIcon
          : MaintenanceHrsIcon,
    },
    {
      color: "#9087C8",
      title: analyticsCategoryLists[3]?.value,
      tagLine: analyticsCategoryLists[3]?.title,
      icon:
        analyticsCategoryLists[3]?.title === "Trips Remaining"
          ? TripsRemainingForkliftIcon
          : analyticsCategoryLists[3]?.title === "Workers"
          ? WorkersIcon
          : analyticsCategoryLists[3]?.title === "Vibration"
          ? VibrationIcon
          : EquipmentIcon,
    },
    {
      color: "#F18080",
      title: analyticsCategoryLists[4]?.value,
      tagLine: analyticsCategoryLists[4]?.title,
      icon: SafetyInfractionsIcon,
    },
  ];

  const [tempratureGraphData, setTempratureGraphData] = useState<any>();
  const [humidityGraphData, setHumidityGraphData] = useState<any>();
  const [hoursOfOperationGraphData, setHoursOfOperationGraphData] =
    useState<any>();
  const [tripsGraphData, setTripsGraphData] = useState<any>();

  const [hoursOfOperationsGraphData, setHoursOfOperationsGraphData] =
    useState<any>();
  const [workersGraphData, setWorkersGraphData] = useState<any>();
  const [predictiveGraphData, setPredictiveGraphData] = useState<any>();
  const [equipmentGraphData, setEquipmentGraphData] = useState<any>();
  const [rawMaterialGraphData, setRawMaterialGraphData] = useState<any>();

  const [tempratureGraphDataStateUpdates, setTempratureGraphDataStateUpdates] =
    useState<any>();
  const [humidityGraphDataStateUpdates, setHumidityGraphDataStateUpdates] =
    useState<any>();
  const [
    hoursOfOperationGraphDataStateUpdates,
    setHoursOfOperationGraphDataStateUpdates,
  ] = useState<any>();
  const [tripsGraphDataStateUpdates, setTripsGraphDataStateUpdates] =
    useState<any>();

  const [
    hoursOfOperationsGraphDataStateUpdates,
    setHoursOfOperationsGraphDataStateUpdates,
  ] = useState<any>();
  const [workersGraphDataStateUpdates, setWorkersGraphDataStateUpdates] =
    useState<any>();
  const [predictiveGraphDataStateUpdates, setPredictiveGraphDataStateUpdates] =
    useState<any>();
  const [equipmentGraphDataStateUpdates, setEquipmentGraphDataStateUpdates] =
    useState<any>();
  const [
    rawMaterialGraphDataStateUpdates,
    setRawMaterialGraphDataStateUpdates,
  ] = useState<any>();

  const [graphOneTitle, setGraphOneTitle] = useState<string>();
  const [graphTwoTitle, setGraphTwoTitle] = useState<string>();
  const [graphThreeTitle, setGraphThreeTitle] = useState<string>();
  const [graphFourTitle, setGraphFourTitle] = useState<string>();
  const [graphFiveTitle, setGraphFiveTitle] = useState<string>();
  const [graphSixTitle, setGraphSixTitle] = useState<string>();
  const [graphSevenTitle, setGraphSevenTitle] = useState<string>();
  const [graphEightTitle, setGraphEightTitle] = useState<string>();
  const [graphNineTitle, setGraphNineTitle] = useState<string>();

  const [isGraphOneDayDataAvailable, setGraphOneIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphTwoDayDataAvailable, setGraphTwoIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphThreeDayDataAvailable, setGraphThreeIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphFourDayDataAvailable, setGraphFourIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphFiveDayDataAvailable, setGraphFiveIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphSixDayDataAvailable, setGraphSixIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphSevenDayDataAvailable, setGraphSevenIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphEightDayDataAvailable, setGraphEightIsDayDataAvailable] =
    useState<boolean>(true);
  const [isGraphNineDayDataAvailable, setGraphNineIsDayDataAvailable] =
    useState<boolean>(true);

  const { width, height } = useWindowDimensions();
  const [chartWidth, setChartWidth] = useState<number>(500);
  const [chartHeight, setChartHeight] = useState<number>(187);

  useEffect(() => {
    if (width <= 1024) {
      setChartWidth(410);
      setChartHeight(135);
    } else if (height <= 600) {
      setChartWidth(410);
      setChartHeight(80);
    } else if (height <= 633) {
      setChartWidth(410);
      setChartHeight(100);
    } else if (height <= 720) {
      setChartWidth(410);
      setChartHeight(118);
    } else if (height <= 768) {
      setChartWidth(410);
      setChartHeight(140);
    } else if (height <= 800) {
      setChartWidth(410);
      setChartHeight(150);
    } else if (height <= 820) {
      setChartWidth(410);
      setChartHeight(160);
    } else if (height <= 820) {
      setChartWidth(410);
      setChartHeight(160);
    } else if (height <= 881) {
      setChartWidth(410);
      setChartHeight(160);
    } else if (height <= 900) {
      setChartWidth(410);
      setChartHeight(176);
    } else if (height <= 937) {
      setChartWidth(410);
      setChartHeight(176);
    } else if (height <= 960) {
      setChartWidth(410);
      setChartHeight(204);
    } else if (height <= 1024) {
      setChartWidth(410);
      setChartHeight(183);
    } else if (height <= 1050) {
      setChartWidth(410);
      setChartHeight(215);
    } else if (height <= 1080) {
      setChartWidth(410);
      setChartHeight(220);
    } else if (height <= 1201) {
      setChartWidth(410);
      setChartHeight(260);
    } else if (height <= 1280) {
      setChartWidth(410);
      setChartHeight(280);
    } else if (height <= 1600) {
      setChartWidth(410);
      setChartHeight(392);
    } else if (width <= 1152) {
      setChartWidth(410);
      setChartHeight(142);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1366) {
      setChartWidth(410);
      setChartHeight(94);
    } else if (width <= 1536) {
      setChartWidth(410);
      setChartHeight(150);
    } else if (width <= 1600) {
      setChartWidth(410);
      setChartHeight(158);
    } else if (width <= 1680) {
      setChartWidth(410);
      setChartHeight(200);
    } else if (width <= 1792) {
      setChartWidth(410);
      setChartHeight(230);
    } else if (width <= 1792) {
      setChartWidth(410);
      setChartHeight(230);
    } else if (width <= 2560) {
      setChartWidth(410);
      setChartHeight(170);
    } else if (width <= 2732) {
      setChartWidth(410);
      setChartHeight(540);
    } else if (width <= 3072) {
      setChartWidth(410);
      setChartHeight(622);
    } else if (width <= 3840) {
      setChartWidth(410);
      setChartHeight(575);
    } else if (width <= 5120) {
      setChartWidth(410);
      setChartHeight(750);
    } else if (width <= 5760) {
      setChartWidth(410);
      setChartHeight(930);
    }
  }, [width, height]);

  useEffect(() => {
    selectedAnalyticListItem?.graphAnalytics?.map(
      (data: any, index: number) => {
        switch (index) {
          case 0:
            setTempratureGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph1]?.analyticsData
            );
            setTempratureGraphData(data?.analytics);
            setGraphOneTitle(data?.title);
            setGraphOneIsDayDataAvailable("day" in data?.analytics);

            break;
          case 1:
            setHumidityGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph2]?.analyticsData
            );
            setHumidityGraphData(data?.analytics);
            setGraphTwoTitle(data?.title);
            setGraphTwoIsDayDataAvailable("day" in data?.analytics);
            break;
          case 2:
            setHoursOfOperationGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph3]?.analyticsData
            );
            setHoursOfOperationGraphData(data?.analytics);
            setGraphThreeTitle(data?.title);
            setGraphThreeIsDayDataAvailable("day" in data?.analytics);
            break;
          case 3:
            setTripsGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph4]?.analyticsData
            );
            setTripsGraphData(data?.analytics);
            setGraphFourTitle(data?.title);
            setGraphFourIsDayDataAvailable("day" in data?.analytics);
            break;
          case 4:
            setHoursOfOperationsGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph5]?.analyticsData
            );
            setHoursOfOperationsGraphData(data?.analytics);
            setGraphFiveTitle(data?.title);
            setGraphFiveIsDayDataAvailable("day" in data?.analytics);
            break;
          case 5:
            setWorkersGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph6]?.analyticsData
            );
            setWorkersGraphData(data?.analytics);
            setGraphSixTitle(data?.title);
            setGraphSixIsDayDataAvailable("day" in data?.analytics);
            break;
          case 6:
            setPredictiveGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph7]?.analyticsData
            );
            setPredictiveGraphData(data?.analytics);
            setGraphSevenTitle(data?.title);
            setGraphSevenIsDayDataAvailable("day" in data?.analytics);
            break;
          case 7:
            setEquipmentGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph8]?.analyticsData
            );
            setEquipmentGraphData(data?.analytics);
            setGraphEightTitle(data?.title);
            setGraphEightIsDayDataAvailable("day" in data?.analytics);
            break;
          case 8:
            setRawMaterialGraphDataStateUpdates(
              data?.analytics[selectedFormatGraph9]?.analyticsData
            );
            setRawMaterialGraphData(data?.analytics);
            setGraphNineTitle(data?.title);
            setGraphNineIsDayDataAvailable("day" in data?.analytics);
            break;
          default:
          // setTempratureGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          // setTempratureGraphData(data?.data);
        }
      }
    );
  }, [selectedAnalyticListItem]);

  useEffect(() => {
    getTempratureGraphData();
    getHumidityGraphData();
    getHoursOfOperationGraphData();
    getTripsGraphData();
    getHoursOfOperationsGraphData();
    getWorkersGraphData();
    getPredictiveGraphData();
    getEquipmentGraphData();
    getRawMaterialGraphData();
  }, [
    tempratureGraphDataStateUpdates,
    humidityGraphDataStateUpdates,
    hoursOfOperationGraphDataStateUpdates,
    tripsGraphDataStateUpdates,

    hoursOfOperationsGraphDataStateUpdates,
    workersGraphDataStateUpdates,
    predictiveGraphDataStateUpdates,
    equipmentGraphDataStateUpdates,
    rawMaterialGraphDataStateUpdates,
  ]);

  const [updatedTempratureGraphData, setUpdatedTempratureGraphData] =
    useState<any>();
  const [updatedHumidityGraphData, setUpdatedHumidityGraphData] =
    useState<any>();
  const [
    updatedHoursOfOperationGraphData,
    setUpdatedHoursOfOperationGraphData,
  ] = useState<any>();
  const [updatedTripsGraphData, setUpdatedTripsGraphData] = useState<any>();

  const [
    updatedHoursOfOperationsGraphData,
    setUpdatedHoursOfOperationsGraphData,
  ] = useState<any>();
  const [updatedWorkersGraphData, setUpdatedWorkersGraphData] = useState<any>();
  const [updatedPredictiveGraphData, setUpdatedPredictiveGraphData] =
    useState<any>();
  const [updatedEquipmentGraphData, setUpdatedEquipmentGraphData] =
    useState<any>();
  const [updatedRawMaterialGraphData, setUpdatedRawMaterialGraphData] =
    useState<any>();

  const getTempratureGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(tempratureGraphDataStateUpdates),

        color:
          graphOneTitle === "Energy Consumed (kWh)" ? "#9486DA" : "#0C8ACC",
      },
    ];

    setUpdatedTempratureGraphData(data);
  };

  const getHumidityGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(humidityGraphDataStateUpdates),

        color:
          graphTwoTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphTwoTitle === "Hours of Operation"
            ? "#949FFF"
            : "#ED6A6A",
      },
    ];
    setUpdatedHumidityGraphData(data);
  };

  const getHoursOfOperationGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(hoursOfOperationGraphDataStateUpdates),

        color: graphThreeTitle === "Hours of Operation" ? "#949FFF" : "#9486DA",
      },
    ];
    setUpdatedHoursOfOperationGraphData(data);
  };

  const getTripsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(tripsGraphDataStateUpdates),

        color:
          graphFourTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphFourTitle === "Hours of Operation"
            ? "#949FFF"
            : "#949FFF",
      },
    ];

    setUpdatedTripsGraphData(data);
  };

  const getHoursOfOperationsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(hoursOfOperationsGraphDataStateUpdates),

        color:
          graphFiveTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphFiveTitle === "Hours of Operation"
            ? "#949FFF"
            : "#949FFF",
      },
    ];

    setUpdatedHoursOfOperationsGraphData(data);
  };

  const getWorkersGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(workersGraphDataStateUpdates),

        color:
          graphSixTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphSixTitle === "Hours of Operation"
            ? "#949FFF"
            : "#FF718B",
      },
    ];

    setUpdatedWorkersGraphData(data);
  };

  const getPredictiveGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(predictiveGraphDataStateUpdates),

        color:
          graphSevenTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphSevenTitle === "Hours of Operation"
            ? "#949FFF"
            : "#FABB70",
      },
    ];

    setUpdatedPredictiveGraphData(data);
  };

  const getEquipmentGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(equipmentGraphDataStateUpdates),

        color:
          graphEightTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphEightTitle === "Hours of Operation"
            ? "#949FFF"
            : "#00A3FF",
      },
    ];

    setUpdatedEquipmentGraphData(data);
  };

  const getRawMaterialGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(rawMaterialGraphDataStateUpdates),

        color:
          graphNineTitle === "Energy Consumed (kWh)"
            ? "#9486DA"
            : graphNineTitle === "Hours of Operation"
            ? "#949FFF"
            : "#D365D6",
      },
    ];

    setUpdatedRawMaterialGraphData(data);
  };

  const graphDataManipulation = (analyticsGraphData: any) => {
    let manipulatedGraphData = [];
    if (analyticsGraphData) {
      if (analyticsGraphData) {
        for (let i = 0; i < analyticsGraphData?.length; i++) {
          manipulatedGraphData.push([
            // new Date(analyticsGraphData[i]?.node).getTime(),
            analyticsGraphData[i]?.count,
          ]);
        }
      }
    }
    return manipulatedGraphData;
  };

  // Updated Time 12Hrs Format - Day

  let times: any = [],
    periods: any = ["AM", "PM"],
    hours: any = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop: any = null,
    hour: any = null,
    min: any = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const currentTime = moment().format("h A");
  const xAxisArray = times.filter(
    (value: any) =>
      moment(value, ["h A"]).format("HH") <
      moment(currentTime, ["h A"]).format("HH")
  );
  const xAxisTimeArray = xAxisArray.slice(
    xAxisArray.length - 10,
    xAxisArray.length
  );
  const xAxisNewValueTime = Array.from(xAxisTimeArray, (ps) => ps);

  // Updated Time 24Hrs Format - Day

  var hoursPerDay: any = 24;
  var xAxisNewtime: any = [];

  function timeOneDay() {
    var formattedTime;
    var newTimeFormat;
    for (let i = 0; i < hoursPerDay + 1; i++) {
      formattedTime = moment().subtract(i, "hours").format("HH");
      newTimeFormat = formattedTime + ":00";
      xAxisNewtime.unshift(newTimeFormat);
    }
    const newTimePop = xAxisNewtime.pop();
  }
  timeOneDay();

  //Updated Day List - Week

  const today: any = moment();
  const xAxisWeek: any = Array(7)
    .fill(7)
    .map(() => today.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueWeek = xAxisWeek.reverse();

  //Updated Day List - Month

  const todayMonth: any = moment();
  const xAxisMonth: any = Array(30)
    .fill(30)
    .map(() => todayMonth.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueMonth = xAxisMonth.reverse();

  // Year

  let monthName: any = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  );
  let currentDate: any = new Date();

  let result: any = [];
  currentDate.setDate(1);
  for (let i = 0; i <= 11; i++) {
    result.push(
      monthName[currentDate.getMonth()]
      // +
      //   " " +
      //   currentDate.getFullYear().toString().substr(-2)
    );
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  const xAxisNewValue: any = result.reverse();
  const xAxisValueYear: any = xAxisNewValue.slice(
    xAxisNewValue.length - 12,
    xAxisNewValue.length
  );

  const [xAxisChartDataGraph1, setXAxisChartDataGraph1] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph2, setXAxisChartDataGraph2] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph3, setXAxisChartDataGraph3] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph4, setXAxisChartDataGraph4] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph5, setXAxisChartDataGraph5] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph6, setXAxisChartDataGraph6] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph7, setXAxisChartDataGraph7] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph8, setXAxisChartDataGraph8] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph9, setXAxisChartDataGraph9] =
    useState<any>(xAxisNewValueWeek);

  const [xAxisIntervalGraph1, setXAxisIntervalGraph1] = useState(0);
  const [xAxisIntervalGraph2, setXAxisIntervalGraph2] = useState(0);
  const [xAxisIntervalGraph3, setXAxisIntervalGraph3] = useState(0);
  const [xAxisIntervalGraph4, setXAxisIntervalGraph4] = useState(0);
  const [xAxisIntervalGraph5, setXAxisIntervalGraph5] = useState(0);
  const [xAxisIntervalGraph6, setXAxisIntervalGraph6] = useState(0);
  const [xAxisIntervalGraph7, setXAxisIntervalGraph7] = useState(0);
  const [xAxisIntervalGraph8, setXAxisIntervalGraph8] = useState(0);
  const [xAxisIntervalGraph9, setXAxisIntervalGraph9] = useState(0);

  const selectList = [
    { label: "Real Time", value: "Real Time" },
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];
  const monthFomrat = "{value:%m/%e}";
  const dayFormat = "{value:%H:00}";
  const yearFormat = "{value:%b}";

  const [formatGraph1, setFormatGraph1] = useState(monthFomrat);
  const [formatGraph2, setFormatGraph2] = useState(monthFomrat);
  const [formatGraph3, setFormatGraph3] = useState(monthFomrat);
  const [formatGraph4, setFormatGraph4] = useState(monthFomrat);
  const [formatGraph5, setFormatGraph5] = useState(monthFomrat);
  const [formatGraph6, setFormatGraph6] = useState(monthFomrat);
  const [formatGraph7, setFormatGraph7] = useState(monthFomrat);
  const [formatGraph8, setFormatGraph8] = useState(monthFomrat);
  const [formatGraph9, setFormatGraph9] = useState(monthFomrat);

  const [selectedFormatGraph1, setSelectedFormatGraph1] = useState("weekly");
  const [selectedFormatGraph2, setSelectedFormatGraph2] = useState("weekly");
  const [selectedFormatGraph3, setSelectedFormatGraph3] = useState("weekly");
  const [selectedFormatGraph4, setSelectedFormatGraph4] = useState("weekly");
  const [selectedFormatGraph5, setSelectedFormatGraph5] = useState("weekly");
  const [selectedFormatGraph6, setSelectedFormatGraph6] = useState("weekly");
  const [selectedFormatGraph7, setSelectedFormatGraph7] = useState("weekly");
  const [selectedFormatGraph8, setSelectedFormatGraph8] = useState("weekly");
  const [selectedFormatGraph9, setSelectedFormatGraph9] = useState("weekly");

  const [selectedRealTimeGraph, setSelectedRealTimeGraph] =
    useState("Real Time");

  const handleSelect = (val: string, graphName: string) => {
    if (graphName === "graph1") {
      switch (val) {
        case "Day":
          setFormatGraph1(dayFormat);
          setSelectedFormatGraph1("day");
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);

          break;
        case "Week":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("weekly");
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewValueWeek);
          setXAxisIntervalGraph1(0);
          break;
        case "Month":
          setFormatGraph1(monthFomrat);
          setSelectedFormatGraph1("monthly");
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewValueMonth);
          setXAxisIntervalGraph1(5);
          break;
        case "Year":
          setFormatGraph1(yearFormat);
          setSelectedFormatGraph1("yearly");
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisValueYear);
          setXAxisIntervalGraph1(1);
          break;
        default:
          setFormatGraph1(dayFormat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);
          break;
      }
    }
    if (graphName === "graph2") {
      switch (val) {
        case "Day":
          setFormatGraph2(dayFormat);
          setSelectedFormatGraph2("day");
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewtime);
          setXAxisIntervalGraph2(4);
          break;
        case "Week":
          setFormatGraph2(monthFomrat);
          setSelectedFormatGraph2("weekly");
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewValueWeek);
          setXAxisIntervalGraph2(0);
          break;
        case "Month":
          setFormatGraph2(monthFomrat);
          setSelectedFormatGraph2("monthly");
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewValueMonth);
          setXAxisIntervalGraph2(5);
          break;
        case "Year":
          setFormatGraph2(yearFormat);
          setSelectedFormatGraph2("yearly");
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisValueYear);
          setXAxisIntervalGraph2(1);
          break;
        default:
          setFormatGraph2(dayFormat);
          setSelectedFormatGraph2("day");
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewtime);
          setXAxisIntervalGraph2(4);
          break;
      }
    }
    if (graphName === "graph3") {
      switch (val) {
        case "Day":
          setFormatGraph3(dayFormat);
          setSelectedFormatGraph3("day");
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewtime);
          setXAxisIntervalGraph3(4);
          break;
        case "Week":
          setFormatGraph3(monthFomrat);
          setSelectedFormatGraph3("weekly");
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewValueWeek);
          setXAxisIntervalGraph3(0);
          break;
        case "Month":
          setFormatGraph3(monthFomrat);
          setSelectedFormatGraph3("monthly");
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewValueMonth);
          setXAxisIntervalGraph3(5);
          break;
        case "Year":
          setFormatGraph3(yearFormat);
          setSelectedFormatGraph3("yearly");
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisValueYear);
          setXAxisIntervalGraph3(1);
          break;
        default:
          setFormatGraph3(dayFormat);
          setSelectedFormatGraph3("day");
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewtime);
          setXAxisIntervalGraph3(4);
          break;
      }
    }
    if (graphName === "graph4") {
      switch (val) {
        case "Real Time":
          setSelectedRealTimeGraph("Real Time");
          break;
        case "Day":
          setFormatGraph4(dayFormat);
          setSelectedFormatGraph4("day");
          setTripsGraphDataStateUpdates(tripsGraphData?.day?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewtime);
          setXAxisIntervalGraph4(4);
          setSelectedRealTimeGraph("");
          break;
        case "Week":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("weekly");
          setTripsGraphDataStateUpdates(tripsGraphData?.weekly?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewValueWeek);
          setXAxisIntervalGraph4(0);
          setSelectedRealTimeGraph("");
          break;
        case "Month":
          setFormatGraph4(monthFomrat);
          setSelectedFormatGraph4("monthly");
          setTripsGraphDataStateUpdates(tripsGraphData?.monthly?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewValueMonth);
          setXAxisIntervalGraph4(5);
          setSelectedRealTimeGraph("");
          break;
        case "Year":
          setFormatGraph4(yearFormat);
          setSelectedFormatGraph4("yearly");
          setTripsGraphDataStateUpdates(tripsGraphData?.yearly?.analyticsData);
          setXAxisChartDataGraph4(xAxisValueYear);
          setXAxisIntervalGraph4(1);
          setSelectedRealTimeGraph("");
          break;
        default:
          setFormatGraph4(dayFormat);
          setTripsGraphDataStateUpdates(tripsGraphData?.day?.analyticsData);
          break;
      }
    }

    if (graphName === "graph5") {
      switch (val) {
        case "Day":
          setFormatGraph5(dayFormat);
          setSelectedFormatGraph5("day");
          setHoursOfOperationsGraphDataStateUpdates(
            hoursOfOperationsGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewtime);
          setXAxisIntervalGraph5(4);

          break;
        case "Week":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("weekly");
          setHoursOfOperationsGraphDataStateUpdates(
            hoursOfOperationsGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueWeek);
          setXAxisIntervalGraph5(0);

          break;
        case "Month":
          setFormatGraph5(monthFomrat);
          setSelectedFormatGraph5("monthly");
          setHoursOfOperationsGraphDataStateUpdates(
            hoursOfOperationsGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisNewValueMonth);
          setXAxisIntervalGraph5(5);

          break;
        case "Year":
          setFormatGraph5(yearFormat);
          setSelectedFormatGraph5("yearly");
          setHoursOfOperationsGraphDataStateUpdates(
            hoursOfOperationsGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph5(xAxisValueYear);
          setXAxisIntervalGraph5(1);

          break;
        default:
          setFormatGraph5(dayFormat);
          setHoursOfOperationsGraphDataStateUpdates(
            hoursOfOperationsGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph6") {
      switch (val) {
        case "Day":
          setFormatGraph6(dayFormat);
          setSelectedFormatGraph6("day");
          setWorkersGraphDataStateUpdates(workersGraphData?.day?.analyticsData);
          setXAxisChartDataGraph6(xAxisNewtime);
          setXAxisIntervalGraph6(4);
          break;
        case "Week":
          setFormatGraph6(monthFomrat);
          setSelectedFormatGraph6("weekly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisNewValueWeek);
          setXAxisIntervalGraph6(0);
          break;
        case "Month":
          setFormatGraph6(monthFomrat);
          setSelectedFormatGraph6("monthly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisNewValueMonth);
          setXAxisIntervalGraph6(5);
          break;
        case "Year":
          setFormatGraph6(yearFormat);
          setSelectedFormatGraph6("yearly");
          setWorkersGraphDataStateUpdates(
            workersGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph6(xAxisValueYear);
          setXAxisIntervalGraph6(1);
          break;
        default:
          setFormatGraph6(dayFormat);
          setWorkersGraphDataStateUpdates(workersGraphData?.day?.analyticsData);
          break;
      }
    }

    if (graphName === "graph7") {
      switch (val) {
        case "Day":
          setFormatGraph7(dayFormat);
          setSelectedFormatGraph7("day");
          setPredictiveGraphDataStateUpdates(
            predictiveGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph7(xAxisNewtime);
          setXAxisIntervalGraph7(4);
          break;
        case "Week":
          setFormatGraph7(monthFomrat);
          setSelectedFormatGraph7("weekly");
          setPredictiveGraphDataStateUpdates(
            predictiveGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph7(xAxisNewValueWeek);
          setXAxisIntervalGraph7(0);
          break;
        case "Month":
          setFormatGraph7(monthFomrat);
          setSelectedFormatGraph7("monthly");
          setPredictiveGraphDataStateUpdates(
            predictiveGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph7(xAxisNewValueMonth);
          setXAxisIntervalGraph7(5);
          break;
        case "Year":
          setFormatGraph7(yearFormat);
          setSelectedFormatGraph7("yearly");
          setPredictiveGraphDataStateUpdates(
            predictiveGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph7(xAxisValueYear);
          setXAxisIntervalGraph7(1);
          break;
        default:
          setFormatGraph7(dayFormat);
          setPredictiveGraphDataStateUpdates(
            predictiveGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph8") {
      switch (val) {
        case "Day":
          setFormatGraph8(dayFormat);
          setSelectedFormatGraph8("day");
          setEquipmentGraphDataStateUpdates(
            equipmentGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph8(xAxisNewtime);
          setXAxisIntervalGraph8(4);
          break;
        case "Week":
          setFormatGraph8(monthFomrat);
          setSelectedFormatGraph8("weekly");
          setEquipmentGraphDataStateUpdates(
            equipmentGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph8(xAxisNewValueWeek);
          setXAxisIntervalGraph8(0);
          break;
        case "Month":
          setFormatGraph8(monthFomrat);
          setSelectedFormatGraph8("monthly");
          setEquipmentGraphDataStateUpdates(
            equipmentGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph8(xAxisNewValueMonth);
          setXAxisIntervalGraph8(5);
          break;
        case "Year":
          setFormatGraph8(yearFormat);
          setSelectedFormatGraph8("yearly");
          setEquipmentGraphDataStateUpdates(
            equipmentGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph8(xAxisValueYear);
          setXAxisIntervalGraph8(1);
          break;
        default:
          setFormatGraph8(dayFormat);
          setEquipmentGraphDataStateUpdates(
            equipmentGraphData?.day?.analyticsData
          );
          break;
      }
    }

    if (graphName === "graph9") {
      switch (val) {
        case "Day":
          setFormatGraph9(dayFormat);
          setSelectedFormatGraph9("day");
          setRawMaterialGraphDataStateUpdates(
            rawMaterialGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph9(xAxisNewtime);
          setXAxisIntervalGraph9(4);
          break;
        case "Week":
          setFormatGraph9(monthFomrat);
          setSelectedFormatGraph9("weekly");
          setRawMaterialGraphDataStateUpdates(
            rawMaterialGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph9(xAxisNewValueWeek);
          setXAxisIntervalGraph9(0);
          break;
        case "Month":
          setFormatGraph9(monthFomrat);
          setSelectedFormatGraph9("monthly");
          setRawMaterialGraphDataStateUpdates(
            rawMaterialGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph9(xAxisNewValueMonth);
          setXAxisIntervalGraph9(5);
          break;
        case "Year":
          setFormatGraph9(yearFormat);
          setSelectedFormatGraph9("yearly");
          setRawMaterialGraphDataStateUpdates(
            rawMaterialGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph9(xAxisValueYear);
          setXAxisIntervalGraph9(1);
          break;
        default:
          setFormatGraph9(dayFormat);
          setRawMaterialGraphDataStateUpdates(
            rawMaterialGraphData?.day?.analyticsData
          );
          break;
      }
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <div className={infoIconContainer}>
            {InfoIconList &&
              InfoIconList?.map((value: any, index: any) => {
                return (
                  <InfoIcon
                    color={value?.color}
                    title={value?.title}
                    tagLine={value?.tagLine}
                    icon={value?.icon}
                    fabWidth={60}
                    fabHeight={60}
                    imgWidth={30}
                    imgHeight={30}
                    key={index}
                    infoTextHOne={"#424242"}
                    infoTextHFour={"#666692"}
                  />
                );
              })}
          </div>
        </Grid>
        <Grid item xs={12} className={analyticsGridSection}>
          <div className={analyticsGridSection}>
            <Grid container className={flex1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphOneTitle}</p> {/*Graph1*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph1"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphOneDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                      />
                    </div>
                  </div>

                  <Chart
                    type={
                      graphOneTitle === "Hours of Operation"
                        ? "column"
                        : graphOneTitle === "No. of Pallets" ||
                          graphOneTitle === "Energy Consumed (kWh)"
                        ? "areaspline"
                        : "spline"
                    }
                    minWidth={chartWidth}
                    height={chartHeight}
                    dataPoints={updatedTempratureGraphData}
                    format={formatGraph1}
                    toolTipShared={false}
                    splineWidth={2}
                    graphSequence={"graph1"}
                    tooltipShow={true}
                    isCrosshair={true}
                    dataLabels={false}
                    tabIdentity={"drivers"}
                    pageName={"analyticsPage"}
                    xAxisArray={xAxisChartDataGraph1}
                    xAxisInterval={xAxisIntervalGraph1}
                    graphTitle={graphOneTitle}
                    selectedAnalyticListItem={selectedAnalyticListItem?.title}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphTwoTitle}</p> {/*Graph2*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph2"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphTwoDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                      />
                    </div>
                  </div>

                  <Chart
                    type={
                      graphTwoTitle === "Hours of Operation" ||
                      graphTwoTitle === "Items Rejected "
                        ? "column"
                        : graphTwoTitle === "No. of Pallets" ||
                          graphTwoTitle === "Energy Consumed (kWh)"
                        ? "areaspline"
                        : "spline"
                    }
                    minWidth={chartWidth}
                    height={chartHeight}
                    dataPoints={updatedHumidityGraphData}
                    format={formatGraph2}
                    toolTipShared={false}
                    splineWidth={2}
                    graphSequence={"graph2"}
                    tooltipShow={true}
                    isCrosshair={true}
                    dataLabels={false}
                    tabIdentity={"drivers"}
                    pageName={"analyticsPage"}
                    xAxisArray={xAxisChartDataGraph2}
                    xAxisInterval={xAxisIntervalGraph2}
                    graphTitle={graphTwoTitle}
                    selectedAnalyticListItem={selectedAnalyticListItem?.title}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphThreeTitle}</p> {/*Graph3*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph3"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphThreeDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                      />
                    </div>
                  </div>

                  <Chart
                    type={
                      graphThreeTitle === "Hours of Operation"
                        ? "column"
                        : graphThreeTitle === "No. of Pallets" ||
                          graphThreeTitle === "Energy Consumed (kWh)"
                        ? "areaspline"
                        : "spline"
                    }
                    minWidth={chartWidth}
                    height={chartHeight}
                    dataPoints={updatedHoursOfOperationGraphData}
                    format={formatGraph3}
                    toolTipShared={false}
                    splineWidth={2}
                    graphSequence={"graph3"}
                    tooltipShow={true}
                    isCrosshair={true}
                    dataLabels={false}
                    tabIdentity={"drivers"}
                    pageName={"analyticsPage"}
                    xAxisArray={xAxisChartDataGraph3}
                    xAxisInterval={xAxisIntervalGraph3}
                    graphTitle={graphThreeTitle}
                    selectedAnalyticListItem={selectedAnalyticListItem?.title}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container className={flex1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphFourTitle}</p> {/*Graph4*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph4"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphFourDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                        selectedAnalyticsTitle={selectedAnalyticListItem?.title}
                        graphTitle={graphFourTitle}
                      />
                    </div>
                  </div>

                  {(selectedAnalyticListItem?.title === "Equipment #1" ||
                    selectedAnalyticListItem?.title === "Equipment #2" ||
                    selectedAnalyticListItem?.title === "Equipment #3") &&
                  selectedRealTimeGraph === "Real Time" ? (
                    <RealTimeChart minWidth={chartWidth} height={chartHeight} />
                  ) : (
                    <Chart
                      type={
                        graphFourTitle === "Hours of Operation"
                          ? "column"
                          : graphFourTitle === "No. of Pallets" ||
                            graphFourTitle === "Energy Consumed (kWh)"
                          ? "areaspline"
                          : "spline"
                      }
                      minWidth={chartWidth}
                      height={chartHeight}
                      dataPoints={updatedTripsGraphData}
                      format={formatGraph4}
                      toolTipShared={false}
                      splineWidth={2}
                      graphSequence={"graph4"}
                      tooltipShow={true}
                      isCrosshair={true}
                      dataLabels={false}
                      tabIdentity={"drivers"}
                      pageName={"analyticsPage"}
                      xAxisArray={xAxisChartDataGraph4}
                      xAxisInterval={xAxisIntervalGraph4}
                      graphTitle={graphFourTitle}
                      selectedAnalyticListItem={selectedAnalyticListItem?.title}
                    />
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphFiveTitle}</p> {/*Graph5*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph5"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphFiveDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                      />
                    </div>
                  </div>

                  <Chart
                    type={
                      graphFiveTitle === "Hours of Operation"
                        ? "column"
                        : graphFiveTitle === "No. of Pallets" ||
                          graphFiveTitle === "Energy Consumed (kWh)"
                        ? "areaspline"
                        : "spline"
                    }
                    minWidth={chartWidth}
                    height={chartHeight}
                    dataPoints={updatedHoursOfOperationsGraphData}
                    format={formatGraph5}
                    toolTipShared={false}
                    splineWidth={2}
                    graphSequence={"graph5"}
                    tooltipShow={true}
                    isCrosshair={true}
                    dataLabels={false}
                    tabIdentity={"drivers"}
                    pageName={"analyticsPage"}
                    xAxisArray={xAxisChartDataGraph5}
                    xAxisInterval={xAxisIntervalGraph5}
                    graphTitle={graphFiveTitle}
                    selectedAnalyticListItem={selectedAnalyticListItem?.title}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={analyticsGrid}
              >
                <div className={graphSection}>
                  <div className={incomeCurrentSection}>
                    <p className={incomeText}>{graphSixTitle}</p> {/*Graph6*/}
                    <div
                    // className={customSelectButton}
                    >
                      <Select
                        selectList={selectList}
                        handleSelect={handleSelect}
                        customWidth={"98px"}
                        customHeight={"30px"}
                        graphName={"graph6"}
                        customSelectCustom={customSelect}
                        isGraphDayDataAvailable={isGraphSixDayDataAvailable}
                        pageName={"analyticsPage"}
                        drowpDownTextColor={"#000"}
                        dropDownBgColor={"#fff"}
                        dropDownSelectedBgColor={"#3253DC"}
                      />
                    </div>
                  </div>

                  <Chart
                    type={
                      graphSixTitle === "Hours of Operation"
                        ? "column"
                        : graphSixTitle === "No. of Pallets" ||
                          graphSixTitle === "Energy Consumed (kWh)"
                        ? "areaspline"
                        : "spline"
                    }
                    minWidth={chartWidth}
                    height={chartHeight}
                    dataPoints={updatedWorkersGraphData}
                    format={formatGraph6}
                    toolTipShared={false}
                    splineWidth={2}
                    graphSequence={"graph6"}
                    tooltipShow={true}
                    isCrosshair={true}
                    dataLabels={false}
                    tabIdentity={"drivers"}
                    pageName={"analyticsPage"}
                    xAxisArray={xAxisChartDataGraph6}
                    xAxisInterval={xAxisIntervalGraph6}
                    graphTitle={graphSixTitle}
                    selectedAnalyticListItem={selectedAnalyticListItem?.title}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container className={flex1}>
              {selectedAnalyticListItem?.graphAnalytics[6] && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className={analyticsGrid}
                >
                  <div className={graphSection}>
                    <div className={incomeCurrentSection}>
                      <p className={incomeText}>{graphSevenTitle}</p>{" "}
                      {/*Graph7*/}
                      <div
                      // className={customSelectButton}
                      >
                        <Select
                          selectList={selectList}
                          handleSelect={handleSelect}
                          customWidth={"98px"}
                          customHeight={"30px"}
                          graphName={"graph7"}
                          customSelectCustom={customSelect}
                          isGraphDayDataAvailable={isGraphSevenDayDataAvailable}
                          pageName={"analyticsPage"}
                          drowpDownTextColor={"#000"}
                          dropDownBgColor={"#fff"}
                          dropDownSelectedBgColor={"#3253DC"}
                        />
                      </div>
                    </div>

                    <Chart
                      type={
                        graphSevenTitle === "Hours of Operation"
                          ? "column"
                          : graphSevenTitle === "No. of Pallets" ||
                            graphSevenTitle === "Energy Consumed (kWh)"
                          ? "areaspline"
                          : "spline"
                      }
                      minWidth={chartWidth}
                      height={chartHeight}
                      dataPoints={updatedPredictiveGraphData}
                      format={formatGraph7}
                      toolTipShared={false}
                      splineWidth={2}
                      graphSequence={"graph7"}
                      tooltipShow={true}
                      isCrosshair={true}
                      dataLabels={false}
                      tabIdentity={"drivers"}
                      pageName={"analyticsPage"}
                      xAxisArray={xAxisChartDataGraph7}
                      xAxisInterval={xAxisIntervalGraph7}
                      graphTitle={graphSevenTitle}
                      selectedAnalyticListItem={selectedAnalyticListItem?.title}
                    />
                  </div>
                </Grid>
              )}
              {selectedAnalyticListItem?.graphAnalytics[7] && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className={analyticsGrid}
                >
                  <div className={graphSection}>
                    <div className={incomeCurrentSection}>
                      <p className={incomeText}>{graphEightTitle}</p>{" "}
                      {/*Graph8*/}
                      <div
                      // className={customSelectButton}
                      >
                        <Select
                          selectList={selectList}
                          handleSelect={handleSelect}
                          customWidth={"98px"}
                          customHeight={"30px"}
                          graphName={"graph8"}
                          customSelectCustom={customSelect}
                          isGraphDayDataAvailable={isGraphEightDayDataAvailable}
                          pageName={"analyticsPage"}
                          drowpDownTextColor={"#000"}
                          dropDownBgColor={"#fff"}
                          dropDownSelectedBgColor={"#3253DC"}
                        />
                      </div>
                    </div>

                    <Chart
                      type={
                        graphEightTitle === "Hours of Operation"
                          ? "column"
                          : graphEightTitle === "No. of Pallets" ||
                            graphEightTitle === "Energy Consumed (kWh)"
                          ? "areaspline"
                          : "spline"
                      }
                      minWidth={chartWidth}
                      height={chartHeight}
                      dataPoints={updatedEquipmentGraphData}
                      format={formatGraph8}
                      toolTipShared={false}
                      splineWidth={2}
                      graphSequence={"graph8"}
                      tooltipShow={true}
                      isCrosshair={true}
                      dataLabels={false}
                      tabIdentity={"drivers"}
                      pageName={"analyticsPage"}
                      xAxisArray={xAxisChartDataGraph8}
                      xAxisInterval={xAxisIntervalGraph8}
                      graphTitle={graphEightTitle}
                      selectedAnalyticListItem={selectedAnalyticListItem?.title}
                    />
                  </div>
                </Grid>
              )}
              {selectedAnalyticListItem?.graphAnalytics[8] && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className={analyticsGrid}
                >
                  <div className={graphSection}>
                    <div className={incomeCurrentSection}>
                      <p className={incomeText}>{graphNineTitle}</p>{" "}
                      {/*Graph9*/}
                      <div
                      // className={customSelectButton}
                      >
                        <Select
                          selectList={selectList}
                          handleSelect={handleSelect}
                          customWidth={"98px"}
                          customHeight={"30px"}
                          graphName={"graph9"}
                          customSelectCustom={customSelect}
                          isGraphDayDataAvailable={isGraphNineDayDataAvailable}
                          pageName={"analyticsPage"}
                          drowpDownTextColor={"#000"}
                          dropDownBgColor={"#fff"}
                          dropDownSelectedBgColor={"#3253DC"}
                        />
                      </div>
                    </div>

                    <Chart
                      type={
                        graphNineTitle === "Hours of Operation"
                          ? "column"
                          : graphNineTitle === "No. of Pallets" ||
                            graphNineTitle === "Energy Consumed (kWh)"
                          ? "areaspline"
                          : "spline"
                      }
                      minWidth={chartWidth}
                      height={chartHeight}
                      dataPoints={updatedRawMaterialGraphData}
                      format={formatGraph9}
                      toolTipShared={false}
                      splineWidth={2}
                      graphSequence={"graph9"}
                      tooltipShow={true}
                      isCrosshair={true}
                      dataLabels={false}
                      tabIdentity={"drivers"}
                      pageName={"analyticsPage"}
                      xAxisArray={xAxisChartDataGraph9}
                      xAxisInterval={xAxisIntervalGraph9}
                      graphTitle={graphNineTitle}
                      selectedAnalyticListItem={selectedAnalyticListItem?.title}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ConfigurationContainer;
