import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import LocationIcon from "../../assets/locationIcon.svg";
import TemperatureIcon from "../../assets/temperatureIcon.svg";
import HumidityIcon from "../../assets/humidityIcon.svg";
import ClockIcon from "../../assets/clockIcon.svg";
import PowerIcon from "../../assets/powerIcon.svg";
import Button from "../../elements/Button";
import useTranslation from "../../localization/translations";
import useStyles from "./styles";

const InfoAlertListItem: React.FC<any> = (props) => {
  const {
    data,
    data: {
      title,
      area,
      eqipment,     
      timeStamp,
    },
    handleExpandListItem,
    index,
    selectedNotification,
  } = props;

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [searchOpen, setSearchOpen] = useState<any>(false);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    listItemContainer,
    listItemTitle,
    listItemLocation,    
    collapsedListItem,  
    listItemFooterStyle,
    listItemTimeStyle,
  } = useStyles(appTheme);

  const {
   
  } = useTranslation();



 

  return (
    <>
      <div className={listItemContainer} >
        
          
          <div className={collapsedListItem}>
            <div className={listItemTitle}>{title}</div>
            <div className={listItemLocation}>{area}</div>

            <div className={listItemFooterStyle}>
            <div 
            // className={listItemLocation}
            >{eqipment}</div>

              <span className={listItemTimeStyle}>{timeStamp}</span>
            </div>
          </div>
      </div>
    </>
  );
};
export default InfoAlertListItem;
