import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(() => ({
  configurationImage: (props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
    [muiTheme.breakpoints.down(1537)]: {
      "& img": {
        width: 400,
      },
    },
  }),
  configureTitle: (props: any) => ({
    color: "#8A8A8A",
    paddingTop: "40px",
    [muiTheme.breakpoints.down(801)]: {
      paddingBottom: 18,
    },
  }),
}));

export default useStyles;
