import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(() => ({
  rootContainer: (props: any) => ({
    // marginTop: "84px",
  }),

  analyticsContainerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "14px 12px 0px 12px",
    borderBottom: "1px solid #8693A8",
    paddingBottom: "14px",
  },

  searchBoxSection: {
    flex: 1,
    marginRight: 10,
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 13,
      lineHeight: "17px !important",
    },
  },

  searchClass: {
    border: "1px solid #d7d7d7",
    background: "#fff",
    color: "#000",
    borderRadius: 6,
    "& .MuiIconButton-root": {
      marginRight: 7,
      height: "41px !important",
    },
    "& .MuiInputBase-root": {
      height: "38px !important",
      fontWeight: 500,
      fontFamily: "QualcommNext-Regular !important",
      [muiTheme.breakpoints.down(1537)]: {
        fontSize: 9,
        lineHeight: "17px !important",
      },
    },
  },

  redButtonStyle: {
    background: "#59B974",
    fontSize: 13,
    borderRadius: 5,
    border: "1px solid #DCDADA",
    color: "white",
    textAlign: "center",
    padding: "13px",
    cursor: "pointer",
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 9,
      lineHeight: "17px !important",
      padding: 6,
    },
  },

  redButtonStyleInactive: {
    background: "#fff",
    fontSize: 13,
    borderRadius: 5,
    color: "#000",
    textAlign: "center",
    padding: "13px",
    cursor: "pointer",
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 9,
      lineHeight: "17px !important",
      padding: "6px !important",
    },
  },

  analyticsNotificationListSection: {
    height: "calc(100vh - 146px) !important",
    overflow: "auto",

    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#FFFFFF",
      outline: "1px solid slategrey",
      borderRadius: 5,
    },
  },

  analyticsNotificationListItemsActive: {
    padding: 16,
    margin: 10,
    color: "#fff",
    fontSize: 16,
    border: " 0.5px solid #97AAED",
    borderRadius: 5,
    background: "#5FC069",
    cursor: "pointer",
    [muiTheme.breakpoints.down(1181)]: {
      fontSize: 13,
      lineHeight: "17px !important",
    },
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 13,
      lineHeight: "17px !important",
    },
  },

  analyticsNotificationListItems: {
    padding: 16,
    margin: 10,
    color: "#000",
    fontSize: 16,
    border: " 0.5px solid #97AAED",
    borderRadius: 5,
    background: "#fff",
    cursor: "pointer",
    [muiTheme.breakpoints.down(1181)]: {
      fontSize: 13,
      lineHeight: "17px !important",
    },
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: 13,
      lineHeight: "17px !important",
    },
  },
}));

export default useStyles;
