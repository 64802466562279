import { useState, useEffect } from "react";
import { Switch, Button, Grid, Drawer, Checkbox } from "@mui/material";
import CheckBox from "elements/Checkbox";
import NumberField from "elements/NumberInput";
import SearchBox from "elements/SearchBox";
import ContactsIcon from "../../assets/contactsIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import ClosedIcon from "../../assets/closedIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import RadioButton from "elements/Radio";
import theme from "../../theme/theme";
import useStyles from "./styles";

const CardsList = (props: any) => {
  const { selectedIndex, currentListItemIndex } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [configurationData, setConfigurationData] = useState<any>([]);
  const [subListData, setSubListData] = useState<any>([]);
  const [contacts, setContacts] = useState<any>([]);
  const [availableContacts, setAvailableContacts] = useState<any>([]);
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [contactsIndex, setContactsIndex] = useState<any>(null);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>([]);
  const [deleteContact, setDeleteContact] = useState<boolean>(false);
  const [clickedContacts, setClickedContacts] = useState<any>(null);

  const {
    rootContainer,
    titleSection,
    subListContainer,
    subListItemContainer,
    listItem,
    listItemSection,
    inputSection,
    updateCustomButton,
    itemLabel,
    minValue,
    maxValue,
    minMaxValue,
    contactsContainer,
    contactsSection,
    contactsList,
    contactsCloseIcon,
    availableContactsSection,
    listSection,
    listContactsSection,
    listName,
    addedCheckbox,
    drawerContainer,
    radioButtonSection,
    noContacts,
    searchClass,
    contactsName,
    checkBoxButtonSection,
    checkBoxButtonContainer,
  } = useStyles(appTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("configurationData") || "[]");
    if (data?.length > 0) {
      setConfigurationData(data);
    }
  }, []);

  useEffect(() => {
    if (configurationData?.length > 0 && selectedIndex !== null) {
      setSubListData(
        configurationData[selectedIndex]["subList"][currentListItemIndex]
      );
    }
    setIsEnable(false);
    setClickedContacts(null);
    setContacts([]);
    setAvailableContacts([]);
    setSearchData([]);
  }, [configurationData, selectedIndex, currentListItemIndex]);

  useEffect(() => {
    if (deleteContact) {
      const modifiedArray = searchData?.map((listItemChecked: any) => {
        return { ...listItemChecked, isChecked: false };
      });
      const modifiedAvailableContacts = availableContacts?.map(
        (listItemChecked: any) => {
          return { ...listItemChecked, isChecked: false };
        }
      );
      setSearchData(modifiedArray);
      setAvailableContacts(modifiedAvailableContacts);
      setDeleteContact(false);
    }
  }, [deleteContact]);

  const handleChange = (event: any, index: any) => {
    const newArr = [...subListData?.cardList];
    newArr[index]["isEnabled"] = event.target.checked;
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleSMSCheck = (event: any, index: any) => {
    const newArr = [...subListData?.cardList];
    newArr[index]["smsChecked"] = event.target.checked;
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleEmailCheck = (event: any, index: any) => {
    const newArr = [...subListData?.cardList];
    newArr[index]["emailChecked"] = event.target.checked;
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleRadioSelection = (event: any, index: any) => {
    const newArr = [...subListData?.cardList];
    newArr[index]["configurationType"] = event.target.value;
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleInputChange = (value: any, index: any, type: string) => {
    const newArr = [...subListData?.cardList];
    if (type === "min") {
      newArr[index][type]["value"] = value;
    } else if (type === "max") {
      newArr[index][type]["value"] = value;
    }
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleUpdateConfiguration = () => {
    localStorage.setItem(
      "configurationData",
      JSON.stringify(configurationData)
    );
  };

  const handleContacts = (item: any, index: number) => {
    const modifiedArray = item?.availableContacts?.map(
      (listItemChecked: any) => {
        return { ...listItemChecked, isChecked: false };
      }
    );
    setClickedContacts(index);
    setContactsIndex(index);
    setContacts(item?.addedContacts);
    setAvailableContacts(item?.availableContacts);
    setSearchData(modifiedArray);
    setIsEnable(true);
    setSearchOpen(false);
  };

  const handleClose = () => {
    setIsEnable(false);
    setClickedContacts(null);
  };

  const handleRemoveListItem = (id: any) => {
    const selectedContacts: any = contacts?.find(
      (item: any) => item?.id === id
    );
    const filteredContacts: any = contacts?.filter(
      (item: any) => item?.id !== id
    );
    setDeleteContact(true);
    setContacts(filteredContacts);
    setAvailableContacts([...availableContacts, selectedContacts]);
    setSearchData([...availableContacts, selectedContacts]);
    const newArr: any = [...subListData?.cardList];
    newArr[contactsIndex].addedContacts = filteredContacts;
    newArr[contactsIndex].availableContacts = [
      ...availableContacts,
      selectedContacts,
    ];
    setSubListData({ ...subListData, cardList: newArr });
  };

  const handleAddListItem = (event: any, id: any) => {
    setDeleteContact(false);
    if (searchOpen) {
      const newArrayAvailableContacts: any = [...searchData];
      for (let i = 0; i < newArrayAvailableContacts?.length; i++) {
        if (id === newArrayAvailableContacts[i]?.id) {
          newArrayAvailableContacts[i].isChecked = event.target.checked;
        }
      }
      setSearchData(newArrayAvailableContacts);
      setAvailableContacts(newArrayAvailableContacts);
    } else {
      const newArrayAvailableContacts: any = [...availableContacts];
      for (let i = 0; i < newArrayAvailableContacts?.length; i++) {
        if (id === newArrayAvailableContacts[i]?.id) {
          newArrayAvailableContacts[i].isChecked = event.target.checked;
        }
      }
      setSearchData(newArrayAvailableContacts);
      setAvailableContacts(newArrayAvailableContacts);
    }

    setTimeout(() => {
      const selectedContacts: any = availableContacts?.find(
        (item: any) => item?.id === id
      );
      const filteredContacts: any = availableContacts?.filter(
        (item: any) => item?.id !== id
      );
      const filteredSearchContacts: any = searchData?.filter(
        (item: any) => item?.id !== id
      );
      if (searchOpen) {
        setSearchData(filteredSearchContacts);
      } else {
        setSearchData(filteredContacts);
      }
      setContacts([...contacts, selectedContacts]);
      setAvailableContacts(filteredContacts);
      const newArr: any = [...subListData?.cardList];
      newArr[contactsIndex].addedContacts = [...contacts, selectedContacts];
      newArr[contactsIndex].availableContacts = filteredContacts;
      setSubListData({ ...subListData, cardList: newArr });
    }, 500);
  };

  const handleSearch = (searchValue: any) => {
    let searchResult = availableContacts?.filter((value: any) => {
      return (
        value?.name
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.area
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase())
      );
    });
    setSearchData(searchResult);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchData(availableContacts);
  };

  const radioOptions = [
    {
      id: 0,
      label: "Events",
      value: "event",
    },
    {
      id: 1,
      label: "Alerts",
      value: "alert",
    },
    {
      id: 2,
      label: "Operations",
      value: "operation",
    },
  ];

  return (
    <>
      <div className={rootContainer}>
        <Grid container>
          <Grid item xs={12}>
            <div className={titleSection}>
              <div>{`${subListData?.title} - Configuration`}</div>
              <div className={updateCustomButton}>
                <Button variant="outlined" onClick={handleUpdateConfiguration}>
                  Update Configuration
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        {subListData?.cardList?.map((item: any, index: any) => {
          return (
            <div
              className={subListContainer}
              key={index}
              style={{
                background: index === clickedContacts ? "#DFE6FF" : "#F5F7FF",
              }}
            >
              <div className={subListItemContainer}>
                <div className={listItem}>
                  <div>
                    <Switch style={{
                      color: "#ffffff",
                    }}
                      checked={item?.isEnabled}
                      onChange={(e: any) => handleChange(e, index)}
                    />
                  </div>
                  <div>{item?.title}</div>
                </div>
                <div className={listItemSection}>
                  {(item?.min?.value || item?.min?.value === "") &&
                    (item?.max?.value || item?.max?.value === "") && (
                      <div className={minMaxValue}>
                        <>
                          <div className={itemLabel}>{item?.min?.label}</div>
                          <div className={inputSection}>
                            <NumberField
                              value={item?.min?.value}
                              symbol={item?.min?.symbol}
                              minRange={item?.min?.range}
                              handleInputChange={(val: any) =>
                                handleInputChange(val, index, "min")
                              }
                              disabled={!item?.isEnabled}
                            />
                          </div>
                        </>
                        <>
                          <div className={itemLabel}>{item?.max?.label}</div>
                          <div className={inputSection}>
                            <NumberField
                              value={item?.max?.value}
                              symbol={item?.max?.symbol}
                              maxRange={item?.max?.range}
                              handleInputChange={(val: any) =>
                                handleInputChange(val, index, "max")
                              }
                              disabled={!item?.isEnabled}
                            />
                          </div>
                        </>
                      </div>
                    )}
                  {(item?.min?.value || item?.min?.value === "") &&
                    item?.max?.value === undefined && (
                      <div className={minValue}>
                        <>
                          <div className={itemLabel}>{item?.min?.label}</div>
                          <div className={inputSection}>
                            <NumberField
                              value={item?.min?.value}
                              symbol={item?.min?.symbol}
                              minRange={item?.min?.range}
                              handleInputChange={(val: any) =>
                                handleInputChange(val, index, "min")
                              }
                              disabled={!item?.isEnabled}
                            />
                          </div>
                        </>
                      </div>
                    )}
                  {(item?.max?.value || item?.max?.value === "") &&
                    item?.min?.value === undefined && (
                      <div className={maxValue}>
                        <>
                          <div className={itemLabel}>{item?.max?.label}</div>
                          <div className={inputSection}>
                            <NumberField
                              value={item?.max?.value}
                              symbol={item?.max?.symbol}
                              maxRange={item?.max?.range}
                              handleInputChange={(val: any) =>
                                handleInputChange(val, index, "max")
                              }
                              disabled={!item?.isEnabled}
                            />
                          </div>
                        </>
                      </div>
                    )}
                  <div>
                    <div className={radioButtonSection}>
                      <RadioButton
                        options={radioOptions}
                        selectedValue={item?.configurationType}
                        handleRadioSelection={(e: any) =>
                          handleRadioSelection(e, index)
                        }
                        disabled={!item?.isEnabled}
                      />
                      <CheckBox
                        id={`${index}-sms`}
                        name={`${index}-sms`}
                        label={"SMS"}
                        isChecked={item?.smsChecked}
                        handleCheck={(e: any) => handleSMSCheck(e, index)}
                        disabled={!item?.isEnabled}
                      />
                      <CheckBox
                        id={`${index}-email`}
                        name={`${index}-email`}
                        label={"Email"}
                        isChecked={item?.emailChecked}
                        handleCheck={(e: any) => handleEmailCheck(e, index)}
                        disabled={!item?.isEnabled}
                      />
                    </div>
                    <div className={checkBoxButtonSection}></div>
                    <div className={checkBoxButtonSection}></div>
                  </div>
                  <div className={updateCustomButton}>
                    <Button
                      variant="outlined"
                      startIcon={<img src={ContactsIcon} />}
                      onClick={() => handleContacts(item, index)}
                      disabled={!item?.isEnabled}
                    >
                      Contacts
                      <span className={contactsName}>
                        ({item?.addedContacts?.length})
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {isEnable && (
        <Drawer variant="permanent" anchor="right" className={drawerContainer}>
          <div className={contactsContainer}>
            <div className={contactsSection}>
              <div className={contactsList}>
                <div>
                  Contacts
                  <span className={contactsName}>({contacts?.length})</span>
                </div>
                <div className={contactsCloseIcon} onClick={handleClose}>
                  <img src={CloseIcon} />
                </div>
              </div>
              <div className={listSection}>
                {contacts?.length > 0 ? (
                  contacts?.map((item: any, index: number) => {
                    return (
                      <div className={listContactsSection} key={index}>
                        <div>
                          <img src={ContactsIcon} />
                        </div>
                        <div className={listName}>{item?.name}</div>
                        <div
                          className={contactsCloseIcon}
                          onClick={() => handleRemoveListItem(item?.id)}
                        >
                          <img src={ClosedIcon} />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={noContacts}>No Contacts</div>
                )}
              </div>
            </div>
            <div className={availableContactsSection}>
              <div className={contactsList}>
                {!searchOpen ? (
                  <div>
                    Available Contacts
                    <span className={contactsName}>
                      ({availableContacts?.length})
                    </span>
                  </div>
                ) : (
                  <SearchBox
                    searchInput={searchClass}
                    placeHolder={"Search"}
                    handleSearch={handleSearch}
                    searchIsOpen={true}
                    fontColor={appTheme?.palette?.dashboardList?.darkGrey3}
                  />
                )}

                <div className={contactsCloseIcon}>
                  <img
                    src={searchOpen ? CloseIcon : SearchIcon}
                    alt="search"
                    onClick={searchOpen ? handleSearchClose : handleSearch}
                  />
                </div>
              </div>
              <div className={listSection}>
                {searchData?.length > 0 ? (
                  searchData?.map((item: any, index: number) => {
                    return (
                      <div className={listContactsSection} key={index}>
                        <div>
                          <img src={ContactsIcon} />
                        </div>
                        <div className={listName}>{item?.name}</div>
                        <div>
                          <Checkbox
                            key={item?.id}
                            name={item?.id}
                            className={addedCheckbox}
                            checked={item?.isChecked}
                            onClick={(e) => handleAddListItem(e, item?.id)}
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={noContacts}>No Contacts</div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default CardsList;
